.burger-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  max-width: 575px;
  list-style: none;

  @include vp-1023 {
    width: 100%;
    flex-direction: column;
    align-self: flex-start;
    margin: 0 0 14px 0;
  }

  &__item {
    position: relative;
    margin-right: 31px;

    @include vp-1199 {
      margin-right: 24px;
    }

    @include vp-1023 {
      width: 100%;
      margin: 0 0 3px 0;
    }
  }

  &__item::after {

    @include vp-1023 {
      content: "";
      position: absolute;
      width: 200%;
      height: 1px;
      background-color: $color-pattens-blue;
      bottom: 6px;
      left: -43px;
    }
  }

  &__content {

    @include vp-1023 {
      position: static;
      padding: 18px 0 0 8px;
    }
  }

  &__content::before {
    top: -31px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);

  }

  &__content--features {

    @media (min-width: 1024px) {
      position: absolute;
      max-height: 600px !important;
      top: 74px;
      overflow: visible;
      min-width: 261px;
      background-color: $color-default-white;
      box-shadow: 0px 7px 14px rgba(62, 62, 80, 0.25);
      padding: 20px 20px 3px;
      border-radius: 15px;
      left: -97px;
      transition: opacity $trans-default;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__content--features::before {

    @media (min-width: 1024px) {
      content: "";
      position: absolute;
      pointer-events: none;
      border: 20px solid transparent;
      border-bottom: 20px solid $color-default-white;
      width: 20px;
      height: 20px;
    }
  }

  &__content--about {

    @media (min-width: 1024px) {
      position: absolute;
      max-height: 600px !important;
      top: 74px;
      overflow: visible;
      min-width: 167px;
      background-color: $color-default-white;
      padding: 20px 20px 3px;
      border-radius: 15px;
      left: -64px;
      transition: opacity $trans-default;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__content--about::before {

    @media (min-width: 1024px) {
      content: "";
      position: absolute;
      pointer-events: none;
      border: 20px solid transparent;
      border-bottom: 20px solid $color-default-white;
      width: 20px;
      height: 20px;
    }
  }

  &__content--support {

    @media (min-width: 1024px) {
      position: absolute;
      max-height: 600px !important;
      top: 74px;
      overflow: visible;
      min-width: 185px;
      background-color: $color-default-white;
      padding: 20px 20px 3px;
      border-radius: 15px;
      left: -76px;
      transition: opacity $trans-default;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__content--support::before {

    @media (min-width: 1024px) {
      content: "";
      position: absolute;
      pointer-events: none;
      border: 20px solid transparent;
      border-bottom: 20px solid $color-default-white;
      width: 20px;
      height: 20px;
    }
  }

  &__icon {
    display: block;
    color: $color-bismark;
    fill: none;
    margin: 0 0 0 7px;
    transition: transform $trans-default;
    will-change: transform, color;
  }
}

.burger-menu__button {
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active {
    color: $color-royal-blue;
    outline: 0;
    outline-offset: 0;

    .burger-menu__icon {
      color: $color-royal-blue;
      transform: rotate(180deg);
      transition: transform $trans-default;
      outline: 0;
      outline-offset: 0;
      will-change: transform, color;
    }
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover {
      color: $color-royal-blue;

      .burger-menu__icon {
        color: $color-royal-blue;
        transform: rotate(180deg);
        transition: transform $trans-default;
        will-change: transform, color;
      }

      @media (min-width: 1024px) {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 76px;
          left: 0;
          top: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

.burger-menu__item {
  outline: 0;
  outline-offset: 0;

  @media (min-width: 1024px) {
    &:hover {
      .burger-menu__content {
        opacity: 1;
        transition: opacity $trans-default;
        pointer-events: visible;
      }

      .burger-menu__button {
        color: $color-royal-blue;
      }

      .burger-menu__icon {
        color: $color-royal-blue;
        transform: rotate(180deg);
        transition: transform $trans-default;
        will-change: transform, color;
      }
    }
  }
}

.burger-menu__item.is-active {
  outline: 0;
  outline-offset: 0;

  .burger-menu__button {
    color: $color-royal-blue;
  }

  .burger-menu__icon {
    color: $color-royal-blue;
    transform: rotate(180deg);
    transition: transform $trans-default;
    will-change: transform, color;
  }
}

.burger-menu__content .links-list__item {
  margin: 0 0 16px 0;
}

.burger-menu__content .links-list__link {
  color: $color-haiti;

  &:active {
    color: $color-royal-blue;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover {
      color: $color-royal-blue;
    }
  }
}
