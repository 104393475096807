.header-select {

  .custom-select {
    width: auto;
    padding: 0;
  }

  .custom-select__button {
    font-size: 16px;
    line-height: 21px;
    font-family: $ff-ibm-plex-sans;
    font-weight: 500;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    padding: 0;

    @include vp-1023 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover,
    &:focus {
      border: none;
      color: $color-royal-blue;

      svg {
        color: $color-royal-blue;
      }
    }
  }

  .custom-select__text {
    width: auto;
  }

  .custom-select__icon {
    width: 16px;
    height: 21px;
    margin: 0 0 0 5px;

    svg {
      fill: none;
      color: $color-bismark;
    }
  }

  .custom-select__error {
    display: none;
  }

  .custom-select__list {
    width: 215%;
    border: none;
    max-height: none;
    overflow-y: visible;
  }

  .custom-select__item {
    padding: 0;
    margin: 0 0 10px 0;

    &:hover,
    &:focus {
      border: none;
      background-color: transparent;
      color: $color-royal-blue;
    }
  }

  .custom-select.is-open .custom-select__list {
    padding: 10px;
    border-radius: 15px;
    bottom: 0;
    background-color: $color-default-white;
  }
}
