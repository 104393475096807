.services {
  display: grid;
  grid-template-columns: 0.9fr 1.2fr 1fr 1.2fr 0.7fr;
  margin-left: 57px;

  @include vp-1279 {
    margin: 0;
    grid-template-columns: 0.4fr 0.6fr 0.5fr 0.6fr 0.7fr;
  }

  @include vp-1023 {
    grid-template-columns: 0.5fr 0.6fr 0.5fr 0.6fr 0.5fr;
    width: 90%;
  }

  @include vp-767 {
    width: 100%;
    display: flex;
    flex-direction: column;
    transform: translate(-1px, -17px);
  }

  &__content--support {

    @include vp-1279 {
      .links-list__item:last-of-type {
        width: 100px;
      }
    }

    @include vp-767 {
      .links-list__item:last-of-type {
        width: 100%;
      }
    }
  }

  &__content--about {
    width: 100px;

    @include vp-1023 {
      .links-list__item:first-of-type {
        width: 100px;
      }
    }

    @include vp-767 {
      .links-list__item:first-of-type {
        width: 100%;
      }
    }
  }

  &__element {

    @include vp-767 {
      border-bottom: 1px solid $color-pale-white30;
    }
  }

  &__element.is-active>&__button {
    svg {
      transform: rotate(180deg);
    }
  }

  &__element--features {
    margin: 0 0 0 15px;

    @include vp-1279 {
      margin: 0 0 0 20px;
    }

    @include vp-1023 {
      margin: 0 0 0 4px;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &__element--pricing {
    margin: 0 0 0 4px;

    @include vp-1279 {
      margin: 0 0 0 12px;
    }

    @include vp-1023 {
      margin: 0 0 0 9px;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &__element--download {

    @include vp-1279 {
      margin: 0 0 0 15px;
    }

    @include vp-1023 {
      margin: 0 0 0 20px;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &__element--support {

    @include vp-1279 {
      margin: 0 0 0 15px;
    }

    @include vp-1023 {
      margin: 0 0 0 24px;
      transform: translate(11px, 0px);
    }

    @include vp-767 {
      margin: 0;
      transform: translate(0, 0);
    }
  }

  &__element--lang-select {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  &__button {
    padding: 16px 0 0 0;
    margin: 0 0 16px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp-1279 {
      padding: 0;
      margin: 0 0 7px 0;
    }

    @include vp-1023 {
      padding: 3px 0 0 0;
    }

    @include vp-767 {
      padding: 16px 0 0 0;
    }

    svg {
      transition: transform $trans-default;
    }
  }

  &__button--lang-select {
    justify-content: flex-start;

  }

  .services__button--lang-select {

    .services__button-icon {
      transform: translate(-2px, -1px);
    }

    .services__button-text {
      transform: translate(-4px, 0px);
    }

    .services__icon {
      margin-left: auto;
    }
  }

  &__button--about {

    @include vp-1279 {
      margin: 0 0 13px;
    }

    @include vp-767 {
      margin: 0 0 16px 0;
    }
  }

  &__button--features {

    @include vp-1023 {
      margin: 0 0 7px;
    }

    @include vp-767 {
      margin: 0 0 16px;
    }
  }

  &__button--pricing {

    @include vp-1023 {
      margin: 0 0 13px;
    }

    @include vp-767 {
      margin: 0 0 16px;
    }
  }

  &__button--download {

    @include vp-1023 {
      margin: 0 0 13px;
    }

    @include vp-767 {
      margin: 0 0 16px;
    }
  }

  &__button--support {

    @include vp-1023 {
      margin: 0 0 13px;
    }

    @include vp-767 {
      margin: 0 0 16px;
    }
  }

  &__button--lang-select {

    @include vp-767 {
      margin: 0 0 16px;
    }
  }

  &__icon {
    display: none;

    @include vp-767 {
      display: block;
      color: $color-default-white;
      fill: none;
    }
  }
}

.services__content .links-list__item {
  margin: 0 0 10px;

  @include vp-767 {
    margin: 0 0 10px 0;
  }
}

.services__content .links-list__link {
  color: $color-default-white;
  font-size: 14px;
  line-height: 18px;

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover {
      color: $color-heliotrope;
    }
  }
}
