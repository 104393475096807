.modal-start {
  font-weight: 500;
  line-height: 1.2;
  color: $color-bismark;


  .modal__title {
    margin-top: 12px;
    margin-bottom: 21px;

    @include vp-767 {
      margin-top: 0;
    }
  }

  .steps {
    margin-bottom: 28px;
  }

  .custom-select,
  .custom-input {
    font-size: 15px;
    color: $color-bismark;
  }

  &__btn {
    display: block;
    width: 100%;
    padding-bottom: 12px;
    text-align: center;
  }

  &__text {
    margin: 0 0 14px;
    font-weight: 400;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include vp-1023 {
      margin-bottom: 16px;
    }

    @include vp-767 {
      margin-bottom: 12px;
      font-size: 14px;
    }
  }

  .custom-toggle__label {
    color: $color-haiti;
    font-size: 18px;
    line-height: 1;
  }

  .download-tabs {
    margin: 18px 0 16px;
    min-height: 290px;

    @include vp-1023 {
      min-height: 294px;
    }

    @include vp-767 {
      margin-top: 16px;
      margin-bottom: 28px;
    }
  }

  .form-simple {
    margin: 16px 0 8px;

    @include vp-767 {
      margin-top: 20px;
    }
  }

  .avatars-swiper {
    padding: 11px 0 5px;
    height: 88px;

    @include vp-1023 {
      padding: 11px 0 0;
      height: 78px;
    }

    @include vp-767 {
      position: relative;
      height: 72px;
      margin-right: -29px;
      padding: 0;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        width: 28px;
        z-index: 10;
        background: linear-gradient(-90deg, $color-default-white 32.65%, $color-transparent 100%);
      }
    }
  }

  @include vp-767 {
    .form-field:nth-child(3) {
      padding-bottom: 0;
    }

    .modal__back-link {
      display: none;
    }

    .modal-start__text:first-of-type {
      padding-top: 11px;
    }
  }

  .download-tabs__controls {
    margin-bottom: 20px;

    @include vp-767 {
      margin-bottom: 4px;
      width: unset;
    }
  }
}
