.price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0 0 0 27px;

  @include vp-1079 {
    padding: 0 0 0 18px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 3px;
    top: 12px;
    left: 0;
    background-color: $color-heather;

    @include vp-1079 {
      width: 10px;
      height: 2px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 17px;
    height: 3px;
    top: 12px;
    left: 0;
    background-color: $color-heather;
    transform: rotate(90deg);

    @include vp-1079 {
      width: 10px;
      height: 2px;
    }
  }

  &__qty {
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    font-family: $ff-ibm-plex-sans;
    color: $color-haiti;
    margin: 0 0 5px 0;

    @include vp-1279 {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__duration {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    font-family: $ff-ibm-plex-sans;
    color: $color-heather;

    @include vp-1279 {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.option-title {
  font-size: 20px;
}