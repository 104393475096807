// Vendor
// ---------------------------------

@import "vendor/normalize";
// Swiper 7.4.1
@import "vendor/swiper";

@import "vendor/data-accordion";

// Global
// ---------------------------------

@import "variables";
@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
// ---------------------------------

@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/header-select";
@import "blocks/sign-up";
@import "blocks/log-in";
@import "blocks/for-free";
@import "blocks/opened";
@import "blocks/burger-menu";
@import "blocks/reviews";
@import "blocks/features";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/btn";
@import "blocks/custom-input";
@import "blocks/custom-toggle";
@import "blocks/custom-select";
@import "blocks/tags";
@import "blocks/blog-cards";
@import "blocks/card";
@import "blocks/pagination";
@import "blocks/footer-tools";
@import "blocks/footer-copyright";
@import "blocks/services";
@import "blocks/language";
@import "blocks/language-select";
@import "blocks/mark";
@import "blocks/intro";
@import "blocks/links-list";
@import "blocks/info-block";
@import "blocks/reasons";
@import "blocks/about";
@import "blocks/sta-block";
@import "blocks/download-block";
@import "blocks/companies";
@import "blocks/article";
@import "blocks/read-also";
@import "blocks/sharing";
@import "blocks/why";
@import "blocks/features-page";
@import "blocks/features-list";
@import "blocks/features-item";
@import "blocks/faq";
@import "blocks/questions";
@import "blocks/page-links";
@import "blocks/guarantees";
@import "blocks/tariffs";
@import "blocks/modal-features";
@import "blocks/tariffs-list";
@import "blocks/price";
@import "blocks/price-adds";
@import "blocks/functions-list";
@import "blocks/messengers";
@import "blocks/cookie";
@import "blocks/modes";
@import "blocks/data-tabs";
@import "blocks/download-tabs";
@import "blocks/download-page";
@import "blocks/tabs-element";
@import "blocks/steps";
@import "blocks/modal-start";
@import "blocks/form-field";
@import "blocks/faq-block";
@import "blocks/faq-page";
@import "blocks/avatar-toggle";
@import "blocks/avatars-swiper";
@import "blocks/form-simple";
@import "blocks/download-tab-small";
@import "blocks/intro-modal";
@import "blocks/modal-sign";
@import "blocks/divider";
@import "blocks/password-input";
@import "blocks/password-recovery";
@import "blocks/code-input";


// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте @media для отключения ховеров на тач устройствах
// но не используем для текстовых полей ( input, textarea )
/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: $color-default-white;
  }
} */

// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
