.download-tab-small {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  padding: 7px 17px;
  border: 0;
  border-radius: 10px;
  min-width: 142px;

  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: $color-heather;
  background-color: transparent;
  transition: background-color $trans-default;
  cursor: pointer;

  @include vp-767 {
    min-width: unset;
    flex: 1;
    padding: 7px 4px;
    margin-right: 6px;
  }

  &:last-child {
    margin-right: 0;

    @include vp-767 {
      margin-right: 0;
    }
  }

  &__icon {
    margin-left: 12px;
    width: 29px;
    height: 29px;
  }

  &__icon-apple {
    margin-left: 14px;
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
  }

  &.is-active {
    color: $color-default-white;
    background-color: $color-royal-blue;
  }
}
