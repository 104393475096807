.blog-cards {
  background-color: $color-alice-blue;
  padding-top: 52px;
  padding-bottom: 140px;

  @include vp-1279 {
    padding-bottom: 118px;
  }

  @include vp-1023 {
    padding-top: 47px;
    padding-bottom: 97px;
  }

  @include vp-767 {
    padding-top: 39px;
    padding-bottom: 66px;
  }
}

.blog-cards__cards {
  margin-top: 42px;

  @include vp-1279 {
    margin-top: 43px;
  }

  @include vp-1023 {
    margin-top: 38px;
  }

  @include vp-767 {
    margin-top: 35px;
  }
}

.blog-cards__cards-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0 62px;
  row-gap: 62px;
  margin-top: 60px;

  @include vp-1279 {
    padding: 50px 0 112px;
    margin-top: 0;
    row-gap: 83px;
  }

  @include vp-1023 {
    padding: 62px 0 52px;
    row-gap: 57px;
  }

  @include vp-767 {
    padding: 50px 0 0;
    row-gap: 31px;
  }
}
