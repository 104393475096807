.tags {
  margin: -23px 0 0 -16px;

  @include vp-767 {
    margin: -23px 0 0 -20px;
    width: calc(100% + 40px);
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tags__list{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  padding: 0 20px 0 0;
  margin: 0;

  & .tags__item {
    margin: 23px 0  0 16px;
    flex-shrink: 0;
    display: flex;

    &:last-child:after {
      content: "";
      display: block;
      width: 20px;
      height: 100%;
      flex-shrink: 0;
    }
  }

  & .tags__item-link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid $color-heather;
    border-radius: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: $color-haiti;
    transition: border-color $trans-600, background-color $trans-600, color  $trans-600;

    @media (hover: hover),
    screen and (min-width: 0\0) {
      &:hover {
        border: 1px solid $color-royal-blue;
      }
    }
  }

  & .tags__name {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: $color-haiti70;
    margin-right: 8px;
  }

  & .tags__count {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: $color-heather;
  }

  & .active {
    background: $color-royal-blue;
    color: $color-default-white;
    border: none;
    cursor: default;

    & .tags__name {
      color: $color-default-white;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {
      &:hover {
        border: none;
      }
    }

    & .tags__name {
      margin-right: 5px;
    }

    & .tags__count {
      color: inherit;
      opacity: 0.6;
    }
  }

  @include vp-1023 {
    padding-right: 0;
  }

  @include vp-767 {
    flex-wrap: nowrap;
    width: auto;
    padding-left: 4px;

  }
}
