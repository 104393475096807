.steps {
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;

  list-style: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: $color-haiti;

  @include vp-767 {
    font-size: 12px;
  }

  &__item {
    padding-right: 12%;

    @include vp-767 {
      padding-right: 20px;
    }
  }

  &__link {
    color: inherit;
    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }


    &.is-wait {
      pointer-events: none;
      color: $color-heather;

      .steps__number {
        background-color: $color-heather;
      }
    }

    &.is-success {
      .steps__number {
        color: inherit;
        background-color: $color-madang;
      }

    }
  }

  &__number {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin-right: 8px;
    width: 22px;
    height: 22px;
    color: $color-default-white;
    background-color: $color-cornflower-blue;
    border-radius: 50%;

    @include vp-767 {
      width: 18px;
      height: 18px;
      padding-bottom: 2px;
    }
  }
}
