.why {
  &__title {
    margin: 0;
    margin-bottom: 110px;
    padding-top: 110px;
    font-size: 48px;
    line-height: 115%;
    font-weight: 700;
    color: $color-haiti;

    @include vp-1279 {
      padding-top: 65px;
      margin-bottom: 70px;
    }

    @include vp-1023 {
      margin-bottom: 103px;
    }

    @include vp-767 {
      font-size: 24px;
      padding-top: 72px;
      margin-bottom: 25px;
    }

    .font-serif {
      display: block;
      font-weight: 700;
    }
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include vp-1279 {
      justify-content: flex-start;
    }

    @include vp-1023 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-items: center;
      grid-row-gap: 60px;
      grid-column-gap: 20px;
    }

    @include vp-767 {
      display: flex;
      width: calc(100% + 20px);
      margin-left: -20px;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding-top: 30px;
    }
  }

  img {
    position: absolute;
    left: -10px;
    top: -50px;

    @include vp-1279 {
      left: 0;
      top: -30px;
    }

    @include vp-1023 {
      top: -60px;
    }

    @include vp-767 {
      top: -48px;
      left: -10px;
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 15px 20px 19px;
    width: 208px;
    height: 100%;
    min-height: 330px;
    background-color: $color-pattens-blue;
    border-radius: 20px;
    margin-right: 35px;
    margin-bottom: 40px;

    &:last-child,
    &:nth-child(5n) {
      margin-right: auto;
    }

    @include vp-1279 {
      width: 154px;
      padding: 14px 10px 14px 14px;
      min-height: 200px;
      margin-right: 30px;

      &:last-child,
      &:nth-child(5n) {
        margin-right: 0;
      }

      &--1 {
        img {
          width: 120px;
          height: 120px;
        }
      }

      &--2 {
        img {
          top: -23px;
          width: 106px;
          height: 106px;
        }
      }

      &--3 {
        img {
          top: -40px;
          width: 126px;
          height: 133px;
        }
      }

      &--4 {
        img {
          top: -48px;
          width: 135px;
          height: 141px;
        }
      }

      &--5 {
        img {
          width: 116px;
          height: 118px;
        }
      }
    }

    @include vp-1023 {
      width: 100%;
      padding: 20px;
      min-height: 340px;
      margin: 0;

      &--1 {
        img {
          width: 220px;
          height: 220px;
        }
      }

      &--2 {
        img {
          width: 175px;
          height: 175px;
          top: -43px;
        }
      }

      &--3 {
        img {
          width: 207px;
          height: 207px;
        }
      }

      &--4 {
        img {
          width: 206px;
          height: 206px;
        }
      }

      &--5 {
        img {
          width: 204px;
          height: 204px;
        }
      }
    }

    @include vp-767 {
      width: 85%;
      max-width: 292px;
      flex-shrink: 0;
      padding: 14px 40px 14px 25px;
      min-height: 316px;

      &:last-child {
        margin-right: 20px;
      }

      &:first-child {
        margin-left: 20px;
      }

      &--1 {
        img {
          width: 194px;
          height: 194px;
        }
      }

      &--2 {
        img {
          width: 172px;
          height: 172px;
        }
      }

      &--3 {
        img {
          width: 177px;
          height: 177px;
        }
      }

      &--4 {
        img {
          width: 175px;
          height: 175px;
        }
      }

      &--5 {
        img {
          width: 175px;
          height: 175px;
        }
      }
    }
  }

  &__highlight {
    margin: 0;
    margin-bottom: 22px;
    padding-top: 102px;
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    color: $color-haiti;

    @include vp-1279 {
      font-size: 20px;
      padding-top: 78px;
      margin-bottom: 7px;
    }

    @include vp-1023 {
      margin-bottom: 20px;
      font-size: 32px;
      padding-top: 125px;
    }

    @include vp-767 {
      margin-bottom: 15px;
      font-size: 28px;
      padding-top: 125px;
    }
  }

  &__text {
    margin: 0;
    margin-bottom: auto;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    color: $color-haiti;

    @include vp-1279 {
      font-size: 15px;
      line-height: 120%;
    }

    @include vp-1023 {
      font-size: 20px;
    }
  }
}
