.cookie {
  display: none;
  position: fixed;
  bottom: 18px;
  left: 10%;
  width: 79%;
  background-color: $color-default-white;
  box-shadow: 0px 14px 30px rgba(62, 62, 80, 0.25);
  border-radius: 20px;
  z-index: 10;

  &.is-active {
    display: block;
  }

  &__wrapper {
    padding: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp-1023 {
      flex-direction: column;
    }
  }

  &__text {
    width: 55%;

    @media (max-width: 1100px) {
      width: 43%;
    }

    @include vp-1023 {
      width: 100%;
      margin: 0 0 25px 0;
    }

    p {
      display: block;
      margin: 0;
      padding: 0;
      color: $color-haiti;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      font-family: $ff-ibm-plex-sans;

      @include vp-1023 {
        text-align: center;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    @include vp-767 {
      flex-direction: column;
      align-items: center;
    }
  }

  &__link {
    display: block;
    padding: 12px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-family: $ff-ibm-plex-sans;
    color: $color-royal-blue;
    border-radius: 12px;
    border: 1px solid $color-royal-blue;
    transition: border $trans-default, color $trans-default;

    @include vp-767 {
      margin: 0 0 25px 0;
    }

    &:active {
      box-shadow: 0 0 3px 3px;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        border: 1px solid $color-sapphire;
        color: $color-sapphire;
      }

      &:active {
        box-shadow: 0 0 3px 3px;
      }
    }
  }

  &__button {
    border-radius: 12px;
    padding: 12px 59px;
    margin: 0 0 0 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-family: $ff-ibm-plex-sans;

    @include vp-767 {
      margin: 0;
    }
  }
}
