.card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 15px;
  width: calc((100% - 80px)/3);
  border-radius: 20px;
  transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% + 26px);
    height: calc(100% + 26px);
    top: -13px;
    left: -13px;
    border-radius: 20px;
    background-color: $color-transparent;
    transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 20px;
    transition: outline $trans-600, background-color $trans-600, color  $trans-600, opacity $trans-600;
  }

  @include vp-1279{
    width: calc((100% - 40px)/2);
  }

  @include vp-1023 {
    width: calc((100% - 40px)/2);
  }

  @include vp-767 {
    width: 100%;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      &::after {
        background-color: $color-heather20;
      }
    }
  }

  &__image-wrapper {
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 21px;
    max-height: 239px;

    & img {

      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }

    @include vp-767 {
      border-radius: 15px;
    }
  }

  &__content {
  }

  &__content-info {
    margin-bottom: 13px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $color-heather;

    @include vp-767 {
      margin-bottom: 16px;
    }
  }

  &__date {
    font: inherit;
    margin-bottom: 3px;
  }

  &__tags{
    font: inherit;
  }

  &__tag {
    position: relative;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: $color-heather;
    margin-right: 6px;
    z-index: 2;
    text-transform: uppercase;
    transition: outline $trans-600, background-color $trans-600, color  $trans-600, opacity $trans-600;

    &::before{
      content: "#";
      display: inline-block;
      margin-right: 2px;
    }

    &::after {
      content: "";
      top: 50%;
      left: 50%;
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      background-color: $color-transparent;
      transform: translate(-50%, -50%);
      z-index: 0;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {
      &:hover {
        color: $color-haiti;
      }
    }

    @include vp-767 {
      font-size: 14px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 11px;
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    color: $color-night-rider;

    @include vp-767 {
      font-size: 28px;
      margin-bottom: 13px;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #2D2C32;
    opacity: 0.7;
    margin: 0;
  }

  &--xl {
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin-top: 0;

    @include vp-1279 {
      width: 100%;
    }

    @include vp-1023 {
      width: 100%;
      flex-direction: column;
    }

    @include vp-767 {
      width: 100%;

      &:last-of-type {
        display: none;
      }
    }

    & .card__content {
      margin-left: 40px;
      margin-bottom: 0;

      @include vp-1279 {
        margin-left: 40px;
      }

      @include vp-1023 {
        margin-left: 0;
      }
    }

    & .card__image-wrapper {
      margin-bottom: 0;
      width: 62%;
      max-width: 735px;
      max-height: 394px;

      & img {
        width: 100%;
        height: auto;
        min-height: 100%;
        object-fit: cover;
      }

      @include vp-1279 {
        width: 61%;
        max-height: 290px;
      }

      @include vp-1023 {
        width: 100%;
        max-width: 100%;
        margin-bottom: 24px;

        img {
          width: 100%;
          height: 290px;
        }
      }

      @include vp-767 {
        border-radius: 15px;
        margin-bottom: 16px;
        max-height: 237px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    & .card__title {
      margin-bottom: 11px;
      font-weight: 700;
      font-size: 40px;
      line-height: 130%;
      color: $color-night-rider;

      @include vp-1279 {
        font-size: 32px;
        line-height: 120%;
      }

      @include vp-1023 {
        margin-bottom: 13px;
      }

      @include vp-767 {
        font-size: 28px;
        line-height: 120%;
        margin-bottom: 9px;
      }

      @include vp-359 {
        font-size: 28px;
        line-height: 120%;
      }
    }

    & .card__text {
      padding-right: 5px;

      @include vp-1023 {
        padding-right: 0;
      }
    }

    & .card__date {
      margin-bottom: 5px;

      @include vp-1279 {
        margin-bottom: 2px;
      }
    }

    & .card__tag {
      position: relative;
      font-weight: 700;
      font-size: 14px;
      line-height: 130%;
      color: $color-heather;
      margin-right: 14px;
      z-index: 2;

      @media (hover: hover),
      screen and (min-width: 0\0) {
        &:hover {
          color: $color-haiti;
        }
      }

      @include vp-1279 {
        font-size: 12px;
      }
    }

    & .card__content-info {

      @include vp-1279 {
        margin-bottom: 13px;
      }

      @include vp-1023 {
        margin-bottom: 9px;
      }

      @include vp-767 {
        margin-bottom: 18px;
      }
    }
  }
}
