.page-links__wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 calc((100% - 40px) / 2);
  justify-content: space-between;

  @include vp-1023 {
    flex-wrap: wrap;
  }

  @include vp-767 {
    width: 100%;
    flex-direction: column;
  }
}

.page-links__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc((100% - 40px) / 2);
  background-color: $color-pattens-blue;
  border-radius: 18px;
  cursor: pointer;
  transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;

  &--left {
    .page-links__text {
      p {
        text-align: right;
      }
    }

    .page-links__link-arrow {
      border-right: 1px solid $color-heather;
      border-left: none;

      @include vp-1279 {
        padding: 15px 18px 15px 20px;
      }

      @include vp-767 {
        padding: 15px 14px 15px 16px;
      }
    }

    @include vp-1023 {
      order: 1;
    }
  }

  &:hover {
    background-color: $color-pattens-blue2;
  }

  @include vp-1023 {
    margin-bottom: 0;
    width: 100%;

    &:last-child {
      margin-bottom: 15px;
    }
  }

  @include vp-767 {
    width: 100%;
    margin: 0 0 18px 0;
  }
}

.page-links__text {
  height: 100%;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $color-bismark;
  padding: 15px 24px;

  p {
    margin: 0 0 4px;

    &:last-child {
      margin: 0;
    }

    @include vp-1279 {
      margin: 0 0 6px;
    }
  }

  @include vp-1279 {
    font-size: 16px;
    padding: 15px 20px;
  }

  @include vp-767 {
    font-size: 14px;
    padding: 13px 16px;
  }
}

.page-links__page-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: $color-blue-violet;

  @include vp-1279 {
    font-size: 18px;
    line-height: 120%;
  }

  @include vp-767 {
    font-size: 16px;

    span {
      display: none;
    }
  }
}

.page-links__link-arrow {
  display: flex;
  align-items: center;
  height: 100%;
  color: $color-blue-violet;
  padding: 15px 23px;
  border-left: 1px solid $color-heather;

  @include vp-1279 {
    padding: 15px 18px 15px 20px;
  }

  @include vp-767 {
    padding: 15px 14px 15px 16px;
  }
}
