.pagination{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 52px;

  @include vp-1279 {
    margin-top: 57px;
  }

  @include vp-1023 {
    margin-top: 64px;
  }

  @include vp-767 {
    margin-top: 15px;
  }
}

.pagination__link {
  width: 47px;
  height: 34px;
  background-color: $color-pattens-blue;
  border-radius: 10px;
  flex-grow: 0;
  padding: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2D2C32;
  transition: border-color $trans-600, background-color $trans-600, color  $trans-600, opacity $trans-600;

  &:hover {
    background-color: $color-mauve;
  }

  @include vp-767 {
    width: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $color-transparent;

    span {
      display: none;
    }

    &:hover {
      background-color: unset;
      opacity: 0.5;
    }
  }

  &--prev {
    margin-right: 18px;

    @include vp-767 {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292892 8.70711C-0.0976315 8.31658 -0.0976315 7.68342 0.292892 7.29289L6.65685 0.928932C7.04738 0.538408 7.68054 0.538408 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292892 8.70711ZM18 9H1V7H18V9Z' fill='%232D2C32'/%3E%3C/svg%3E%0A");
    }
  }

  &--next {
    margin-left: 18px;

    @include vp-767 {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L11.3431 0.928932C10.9526 0.538408 10.3195 0.538408 9.92893 0.928932C9.53841 1.31946 9.53841 1.95262 9.92893 2.34315L15.5858 8L9.92893 13.6569C9.53841 14.0474 9.53841 14.6805 9.92893 15.0711C10.3195 15.4616 10.9526 15.4616 11.3431 15.0711L17.7071 8.70711ZM0 9H17V7H0V9Z' fill='%232D2C32'/%3E%3C/svg%3E%0A");
      margin-left: 8px;
    }
  }

  &--more {
    display: none;

    @include vp-767 {
      display: inline-block;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='3' viewBox='0 0 14 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44 2.97C0.948 2.97 0.582 2.85 0.342 2.61C0.114 2.358 0 2.04 0 1.656V1.332C0 0.948 0.114 0.63 0.342 0.378C0.582 0.126 0.948 0 1.44 0C1.944 0 2.31 0.126 2.538 0.378C2.766 0.63 2.88 0.948 2.88 1.332V1.656C2.88 2.04 2.766 2.358 2.538 2.61C2.31 2.85 1.944 2.97 1.44 2.97Z' fill='black'/%3E%3Cpath d='M6.80133 2.97C6.30933 2.97 5.94333 2.85 5.70333 2.61C5.47533 2.358 5.36133 2.04 5.36133 1.656V1.332C5.36133 0.948 5.47533 0.63 5.70333 0.378C5.94333 0.126 6.30933 0 6.80133 0C7.30533 0 7.67133 0.126 7.89933 0.378C8.12733 0.63 8.24133 0.948 8.24133 1.332V1.656C8.24133 2.04 8.12733 2.358 7.89933 2.61C7.67133 2.85 7.30533 2.97 6.80133 2.97Z' fill='black'/%3E%3Cpath d='M12.1627 2.97C11.6707 2.97 11.3047 2.85 11.0647 2.61C10.8367 2.358 10.7227 2.04 10.7227 1.656V1.332C10.7227 0.948 10.8367 0.63 11.0647 0.378C11.3047 0.126 11.6707 0 12.1627 0C12.6667 0 13.0327 0.126 13.2607 0.378C13.4887 0.63 13.6027 0.948 13.6027 1.332V1.656C13.6027 2.04 13.4887 2.358 13.2607 2.61C13.0327 2.85 12.6667 2.97 12.1627 2.97Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
}

.pagination__list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.pagination__item {
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: $color-default-black;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 9px;

  & a, span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color $trans-600, background-color $trans-600, color  $trans-600, opacity $trans-600;

    &:hover {
      opacity: 0.5;
    }
  }

  &.active {
    background-color: #D9B8FF;
    border-radius: 50%;
    cursor: default;

    & span:hover {
      opacity: 1;
    }
  }

  @include vp-767 {
    &:nth-child(n + 5) {
      display: none;
    }
  }
}
