.tariffs {

  .container {

    @include vp-1079 {
      padding: 0 66px;
    }

    @include vp-1023 {
      padding: 0 45px;
    }

    @include vp-767 {
      padding: 0 20px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px 0;

    @include vp-1023 {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 21px 0;
    }

    @include vp-767 {
      margin: 0 0 20px 0;
    }

    span {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: $color-haiti;
      font-family: $ff-ibm-plex-sans;
      padding-top: 32px;

      @include vp-1279 {
        font-size: 16px;
        line-height: 19px;
        max-width: 258px;
        padding-top: 0;
        transform: translate(-4px, -3px);
      }

      @include vp-1023 {
        font-size: 14px;
        line-height: 17px;
        max-width: 100%;
        transform: translate(0, 0);
      }

      @include vp-767 {
        max-width: 258px;
      }
    }
  }

  &__filter {
    display: flex;
    align-items: center;

    @include vp-1023 {
      margin: 0 0 41px 0;
    }

    @include vp-767 {
      margin: 0 0 24px 0;
    }

    @include vp-767 {
      flex-direction: column;
      align-items: flex-start;
    }

    p {
      display: block;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      font-family: $ff-ibm-plex-sans;
      color: $color-haiti;

      @include vp-767 {
        margin: 0 0 24px 0;;
      }
    }
  }

  &__toddler {
    margin: 0 0 0 20px;
    padding: 4px;
    background-color: $color-default-white;
    border-radius: 16px;

    @include vp-1279 {
      margin: 0 0 0 16px;
    }

    @include vp-1079 {
      transform: translate(1px, 7px);
    }

    @include vp-1023 {
      margin: 0 0 0 20px;
      transform: translate(0, 1px);
    }

    @include vp-767 {
      margin: 0;
    }
  }

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 30px;
    background: #b97cff;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #b97cff;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 60px;
  }

  .tariff-switch {
    font-size: 26px;
    line-height: 26px;
    margin: 0 0 23px;
    font-weight: 700;
    color: #2d2c32;
    padding-left: 15px;
  }

  .tariff-switch-toggle {
    display: flex;
    margin-left: 18px;

    @include vp-767 {
      margin-left: 0;
    }
  }

}
