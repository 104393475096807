.log-in {
  margin: 0 0 0 33px;

  @include vp-1199 {
    margin: 0 0 0 22px;
  }

  @include vp-1079 {
    margin: 0 0 0 15px;
  }

  @include vp-1023 {
    margin: 0 0 25px 0;
  }

  &__link {
    font-size: 16px;
    line-height: 21px;
    font-family: $ff-ibm-plex-sans;
    font-weight: 500;

    &:hover,
    &:focus {
      color: $color-royal-blue;
    }
  }
}
