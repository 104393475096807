.code-input {
  input {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    transition: all $trans-default;

    &:hover,
    &:focus {
      border-color: $color-royal-blue;
    }
  }
}
