.features-page {
  &__intro {
    margin-bottom: 72px;

    @include vp-1023 {
      margin-top: -80px;
      margin-bottom: 60px;
    }

    @include vp-767 {
      margin-top: -76px;
      margin-bottom: 72px;
    }
  }

  &__sta {
    margin-bottom: 72px;

    @include vp-1279 {
      margin-bottom: 70px;
    }

    @include vp-767 {
      margin-bottom: 73px;
    }
  }

  &__download-block {
    margin-bottom: 140px;

    @include vp-1279 {
      margin-bottom: 119px;
    }

    @include vp-1023 {
      margin-bottom: 85px;
    }

    @include vp-767 {
      margin-bottom: 72px;
    }
  }

  &__features-list {
    margin-bottom: 130px;

    @include vp-1279 {
      margin-bottom: 110px;
    }

    @include vp-1023 {
      margin-bottom: 70px;
    }
  }

  &__info-block {
    margin-bottom: 120px;

    @include vp-1279 {
      margin-bottom: 100px;
    }

    @include vp-1023 {
      margin-bottom: 70px;
    }
  }

  &__features-item {
    margin-bottom: 68px;
    height: 100vh;

    .container {
      position: sticky;
      top: 20vh;

      @include vp-767 {
        position: static;
      }
    }

    @include vp-1279 {
      margin-bottom: 36px;
      height: 90vh;
    }

    @include vp-767 {
      height: auto;
      margin-bottom: 40px;
    }
  }

  &__page-links{
    margin-bottom: 72px;

    @include vp-767 {
      margin-bottom: 53px;
    }
  }

  &__messengers {
    margin-bottom: 107px;

    @include vp-767 {
      margin-bottom: 70px;
    }
  }
}
