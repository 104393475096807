.faq-page {
  &__intro {
    margin-bottom: 54px;

    @include vp-1279 {
      margin-bottom: 42px;
    }

    @include vp-1023 {
      margin-bottom: 72px;
    }

    @include vp-767 {
      margin-bottom: 33px;
    }
  }

  &__download-block {
    margin-bottom: 140px;

    @include vp-1023 {
      margin-bottom: 85px;
    }

    @include vp-767 {
      margin-bottom: 70px;
    }
  }

  &__sta {
    margin-bottom: 120px;

    @include vp-1279 {
      margin-bottom: 70px;
    }

    @include vp-767 {
      margin-bottom: 73px;
    }

    @include vp-1023 {
      margin-bottom: 69px;
    }
  }

  &__faq-block {
    margin-bottom: 120px;

    @include vp-1023 {
      margin-bottom: 64px;
    }

    @include vp-767 {
      margin-bottom: 52px;
    }
  }
}
