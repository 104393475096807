.modes {
  color: $color-haiti;
}

.modes__wrapper {
  display: flex;

  & > * {
    width: calc(100% / 2);
  }

  @include vp-1279 {
    flex-wrap: wrap;

    & > * {
      width: 100%;
    }
  }
}

.modes__item {
  padding-left: 30px;

  @include vp-1279 {
    padding-left: 0;
  }

  @include vp-767 {
    margin-bottom: 32px;

    &:last-child {
      margin: 0;
    }
  }
}

.modes__title {
  font-weight: 500;
  font-size: 32px;
  line-height: 128%;
  padding-right: 50px;
  margin: 0;

  @include vp-1279 {
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    padding-right: 325px;
    margin-bottom: 31px;
  }

  @include vp-1023 {
    padding-right: 115px;
    margin-bottom: 28px;
  }


  @include vp-767 {
    font-weight: 500;
    font-size: 20px;
    line-height: 117%;
    padding-right: 0;
    margin-bottom: 35px;
  }
}

.modes__modes {
  display: flex;

  & > * {
    width: calc(100% / 2);
  }

  @include vp-1279 {

    & > * {
      width: calc((100% - 192px) / 2);
    }
  }

  @include vp-1023 {
    & > * {
      width: 50%;

      &:nth-child(2n) {
        padding-left: 12px;
      }
    }
  }

  @include vp-767 {
    flex-wrap: wrap;
    & > * {
      width: 100%;
      padding-left: 0;

      &:nth-child(2n) {
        padding-left: 0;
      }
    }
  }
}

.modes__list-item {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin: 0 0 7px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    flex-shrink: 0;
    content: "";
    display: block;
    background-color: $color-cornflower-blue;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin: 6px 10px 6px 6px;
  }
}

.modes__list {
  list-style: none;
  padding: 0;
  margin: 0;

  @include vp-1279 {
    padding-right: 75px;
  }

  @include vp-767 {
    padding-right: 59px;
  }
}

.modes__item-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  margin: 0 0 20px;

  @include vp-1279 {
    margin: 0 0 17px;
  }
}
