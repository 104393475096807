.features {
  // margin-bottom: 240px;

  @include vp-1279 {
    margin-bottom: 75px;
  }

  @include vp-1023 {
    margin-bottom: 52px;
  }

  .container {
    height: 100%;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    overflow: visible;

    @include vp-1023 {
      flex-direction: column;
      height: auto;
      top: 30%;
      // transform: translateY(-50%);
    }

    @include vp-767 {
      position: static;
      transform: none;
      width: calc(100% + 40px);
      margin: 0 0 0 -20px;
      padding: 0 20px;
      overflow: hidden;
    }

    &> * {
      width: calc((100% - 25px) / 2);

      @include vp-1023 {
        width: 100%;
      }
    }

    // &--green {
    //   .features__nav-button {
    //     background-color: $color-mantis;
    //   }
    // }

    // &--lilac {
    //   .features__nav-button {
    //     background-color: $color-heliotrope;
    //   }
    // }

    // &--blue {
    //   .features__nav-button {
    //     background-color: $color-royal-blue;
    //   }
    // }
  }

  &__sticky {
    position: sticky;
    top: 0;
    height: 100vh;
    min-height: max-content;

    @include vp-767 {
      height: auto;
    }
  }

  &__tabs {
    // position: sticky;
    // top: 0;
    padding: 10vh 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    // transform: translateY(-50%);
    will-change: transform;
    transition: transform $trans-default;

    @include vp-1023 {
      position: relative;
      padding: 0;
      min-height: 60px;
    }

    @include vp-767 {
      position: static;
      display: flex;
      align-items: stretch;
    }
  }

  &__tabs-content {
    height: 90vh;
    // position: sticky;
    // top: 0;
    display: flex;
    align-items: center;

    position: absolute;
    right: 0;

    @include vp-1023 {
      justify-content: center;
      height: unset;
      position: static;
      // position: relative;
      // left: 50%;
      // transform: translateX(-50%);
    }

    @include vp-767 {
      display: none;
    }
  }

  &__tabs-img {
    width: 540px;
    height: 500px;
    position: relative;
    // background-color: $color-heather;
    // border-radius: 40px;

    @include vp-767 {
      width: 292px;
      height: 273px;
    }
  }

  &__element {
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 25px 0;

    @include vp-767 {
      width: 100%;
      padding: 0 0 18px 0;
      // position: absolute;
      // top: 0;
      // left: 50%;
      opacity: 1;
      // transform: translateX(-50%);
      // transition: opacity $trans-default;
    }

    &.swiper-slide-active {
      .features__button {
        color: $color-haiti;
      }
    }

    &.active {
      .features__button {
        color: $color-haiti;
      }

      @include vp-767 {
        opacity: 1;
      }
    }
  }

  &__button.btn {
    display: block;
    color: $color-heather;
    font-size: 40px;
    line-height: 130%;
    font-weight: 700;;
    text-align: left;
    padding: 0;
    width: 582px;
    // pointer-events: none;

    @include vp-767 {
      width: 100%;
      padding: 0 0 12px 0;
      font-size: 24px;
      text-align: center;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        color: $color-haiti;
      }
    }
  }

  .features__content {
    @include vp-767 {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .features__img-mobile {
    display: none;

    @include vp-767 {
      display: block;
      // padding-top: 37px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 292px;
      height: auto;
      margin-top: auto;

      .btn {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 12px;

        @include vp-374 {
          font-size: 14px;
        }

        svg {
          margin-left: 7px;
          width: 20px;
          height: 17px;

          @include vp-374 {
            width: 16px;
            height: 15px;
          }
        }
      }
    }

    @include vp-374 {
      width: 100%;
    }
  }

  &__text {
    padding-top: 14px;
    margin: 0;
    width: 465px;
    color: $color-bismark;
    font-size: 18px;
    line-height: 130%;
    font-weight: 500;

    @include vp-767 {
      // display: none;
      // padding: 0 20px;
      padding: 0;
      margin-bottom: 16px;
      width: 100%;
      text-align: center;
    }
  }

  &__img {
    // padding-top: 60px;
    position: absolute;
    // right: 40px;
    // top: 50%;
    // transform: translateY(-50%);
    // width: 540px;
    // height: 500px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.3s;

    @include vp-1279 {
      display: none;
    }

    &.active {
      opacity: 1;
    }

    .btn {
      position: absolute;
      bottom: 28px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      font-size: 16px;
      font-weight: 500;
      padding: 9px 15px;

      @include vp-767 {
        bottom: 22px;
        padding: 6px 12px;
      }
    }
  }

  &__classic-link {
    display: block;
    margin-top: 10px;
    text-decoration: underline;
    color: var(--swiper-theme-color);
  }

  // &__nav {
  //   display: none;

  //   @include vp-767 {
  //     width: auto;
  //     display: flex;
  //     justify-content: flex-end;
  //     margin: 0 40px 0 auto;
  //   }
  // }

  // &__nav-button {
  //   width: 48px;
  //   height: 48px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 0;
  //   color: $color-default-white;
  //   background-color: $color-royal-blue;
  //   border: none;
  //   border-radius: 50%;

  //   &--prev {
  //     margin: 0 8px 0 0;
  //   }

  //   &[disabled] {
  //     background-color: $color-pattens-blue;
  //   }
  // }
}
