.download-page {
  color: $color-haiti;

  &__intro {

    @include vp-1279 {
      margin-bottom: 44px;
    }

    @include vp-767 {
      margin-bottom: 34px;
    }
  }

  &__modes {
    margin-bottom: 118px;

    @include vp-1279 {
      margin-bottom: 59px;
    }

    @include vp-1023 {
      margin-bottom: 62px
    }

    @include vp-767 {
      margin-bottom: 36px;
    }
  }

  &__tabs {
    margin-bottom: 0;

    @include vp-1279 {
      margin-bottom: 100px;
    }

    @include vp-1023 {
      margin-bottom: 73px;
    }

    @include vp-767 {
      margin-bottom: 73px;
    }
  }

  &__faq {
    margin-bottom: 140px;

    @include vp-1279 {
      padding: 0;
      margin-bottom: 120px;
    }

    @include vp-1023 {
      margin-bottom: 114px;
    }

    @include vp-767 {
      padding: 0;
      margin: 0 0 76px;
    }
  }
}
