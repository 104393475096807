.faq-block {
}

.faq-block__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.faq-block__other-questions {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: $color-bismark;
  margin-bottom: 20px;
  text-align: right;

  a {
    margin-left: 8px;
    color: $color-royal-blue;
    text-decoration: underline;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-blue-violet;
      }
    }
  }

  @include vp-1279 {
    margin-bottom: 16px;
    font-size: 16px;

    a {
      margin-left: 4px;
    }
  }

  @include vp-1023 {
    margin-bottom: 20px;
    text-align: left;
  }

  @include vp-767 {
    font-size: 18px;
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.faq-block__questions {
  width: 425px;
  margin: -16px 0 0;
  padding: 23px 61px 23px 0;
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: scroll;


  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-transparent;
    border: none;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-heather;
    width: 6px;
    border-radius: 9px;
  }

  @include vp-1279 {
    width: 297px;
    margin: 0;
    padding-right: 36px;
  }

  @include vp-1023 {
    display: flex;
    position: relative;
    flex-direction: column;
    width: calc(100% - 90px);
    order: -1;
    height: auto;
    padding: 0;
    margin-bottom: 42px;
    overflow: initial;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include vp-767 {
    width: 100%;
    margin-bottom: 20px;
  }
}

.faq-block__mobile {
  display: none;

  @include vp-1023 {
    display: block;
    width: 100%;
  }
}

.faq-block__mobile-button {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: start;
  background-color: $color-solitude;
  border: none;
  border-radius: 8px;
  padding: 12px;
  color: $color-royal-blue;
  transition: color $trans-600;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  & span {
    max-width: 72%;

    @include vp-767 {
      max-width: 84%;
    }
  }

  &:after {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.59889 9.37228C6.06407 8.87591 6.81828 8.87591 7.28347 9.37228L12 14.405L16.7165 9.37228C17.1817 8.87591 17.9359 8.87591 18.4011 9.37228C18.8663 9.86864 18.8663 10.6734 18.4011 11.1698L12 18L5.59889 11.1698C5.1337 10.6734 5.1337 9.86864 5.59889 9.37228Z' fill='%23546E7A'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    margin-left: auto;
    flex-shrink: 0;
  }

  &.open {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.598888 9.12772C1.06407 9.62409 1.81828 9.62409 2.28347 9.12772L7 4.09501L11.7165 9.12772C12.1817 9.62409 12.9359 9.62409 13.4011 9.12772C13.8663 8.63136 13.8663 7.82659 13.4011 7.33022L7 0.5L0.598888 7.33022C0.133704 7.82659 0.133704 8.63136 0.598888 9.12772Z' fill='%23689FFA'/%3E%3C/svg%3E%0A");
    }
  }
}

.faq-block__question-wrapper {
  margin-left: -8px;
  margin-top: -38px;

  & .faq-block__question-scroll-wrapper {
    padding-left: 16px;
    padding-top: 16px;
  }

  @include vp-1023 {
    display: none;
    margin: 0;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    max-height: 80vh;
    padding: 24px 12px 24px 20px;

    & .faq-block__question-scroll-wrapper {
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 13px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: $color-transparent;
        border: none;
        border-radius: 9px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-heather;
        width: 6px;
        border-radius: 9px;
      }
    }

    &.open {
      display: flex;
      flex-direction: column;
      background-color: $color-default-white;
      border-radius: 16px;
      filter: drop-shadow(0px 5px 23px rgba(62, 62, 80, 0.25));
      z-index: 10;
    }
  }
}

.faq-block__question {
  position: relative;
  padding: 16px 8px;
  transition: color $trans-600, background-color $trans-600;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:after {
    content: "";
    width: calc(100% + 32px);
    height: 100%;
    top: 0;
    left: -8px;
    border-radius: 8px;
    position: absolute;
    background-color: $color-transparent;
    transition: color $trans-600, background-color $trans-600;
    z-index: -1;

    @include vp-1023 {
      content: none;
    }
  }

  &.active {
    border-radius: 8px;
    transition: color $trans-600, background-color $trans-600;

    a {
      color: $color-royal-blue;
      transition: color $trans-600;
    }

    &:after {
      background-color: $color-solitude;
      transition: color $trans-600, background-color $trans-600;
    }

    @include vp-1023 {
      background-color: $color-transparent;
      outline: none;
    }
  }

  @include vp-1279 {
    padding: 15px 8px;
  }

  @include vp-1023 {
    min-width: 100%;
  }
}

.faq-block__question-link {
  width: 100%;
  color: $color-haiti;
  font-weight: 500;
  font-size: 20px;
  line-height: 129%;
  transition: color $trans-600;

  @include vp-1279 {
    font-size: 18px;
    line-height: 128%;
  }

  @include vp-1023 {
    width: 100%;
  }
}

.faq-block__answers {
  width: calc(100% - 465px);
  padding: 44px 53px 44px 54px;
  border-radius: 20px;
  background-color: $color-default-white;

  &.active {
    .faq-block__answers-title {
      font-weight: 700;
      color: $color-royal-blue;
    }
  }

  @include vp-1279 {
    width: calc(100% - 350px);
    padding: 31px 40px 31px 39px;
  }

  @include vp-1023 {
    padding: 32px 40px 38px;
    width: calc(100% - 90px);
  }

  @include vp-767 {
    padding: 32px 40px 40px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 16px;
  }
}

.faq-block__answer {
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    display: block;

    & .faq-block__answers-title {
      color: $color-royal-blue;
    }
  }

  @include vp-1279 {
    margin-bottom: 39px;
  }

  @include vp-767 {
    display: none;
  }
}

.faq-block__answers-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  color: $color-haiti;
  margin-bottom: 24px;

  @include vp-1279 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  @include vp-1023 {
    font-size: 24px;
  }

  @include vp-767 {
    display: none;
  }
}

.faq-block__answers-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: $color-bismark;

  @include vp-1279 {
    font-size: 18px;
    line-height: 129%;
  }
}
