.download-tabs__controls {
  border-radius: 16px;
  background-color: $color-default-white;
  padding: 3px 4px 4px;
  width: max-content;
  margin-bottom: 34px;

  @include vp-767 {
    width: 100%;
    margin-bottom: 20px;
  }

  &--grey {
    background-color: $color-alice-blue;
    display: inline-flex;
    flex: 0;
    padding: 3px;
    width: unset;
    border-radius: 10px;

    @include vp-767 {
      display: flex;
      padding: 2px 1px;
    }
  }
}

.download-tabs__controls-wrapper {
  display: flex;
  align-items: center;
  justify-items: flex-start;

  @include vp-767 {
    flex-wrap: wrap;
    width: 100%;
  }
}

.download-tabs__control {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  color: $color-heather;
  border: none;
  background-color: $color-default-white;
  padding: 5px 20px;
  font-weight: 700;
  font-size: 26px;
  line-height: 150%;
  min-width: 206px;
  min-height: 67px;
  margin: 0 4px;
  transition: color $trans-600;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }

  &--android {
    margin-left: 0;

    svg {
      margin-left: 17px;
    }
  }

  &--apple {
    padding-left: 16px;
    width: 214px;

    svg {
      margin-bottom: 5px;
      margin-left: 10px;
    }
  }

  &--windows {
    border: none;
    padding-left: 15px;
    margin-right: 0;
    min-width: 203px;

    svg {
      width: 29px;
      height: 29px;
      margin-left: 26px;
    }
  }

  &.is-active {
    border-radius: 16px;
    background-color: $color-royal-blue;
    color: $color-default-white;
    min-width: 206px;
    line-height: 130%;

    @include vp-1023 {
      padding: 12px 20px;
    }
  }

  &:not(.is-active):hover {
    color: $color-royal-blue;
  }

  @include vp-1279 {
    font-weight: 700;
    font-size: 26px;
    line-height: 130%;
  }

  @include vp-1023 {
    font-size: 22px;
  }

  @include vp-767 {
    width: 100%;
    padding: 10px 20px 10px;
    border-right: none;
    margin: 0 0 6px;

    &--apple {
      svg {
        width: 22px;
        margin-left: 29px;
      }
    }

    &--windows {
      margin-bottom: 0;

      svg {
        height: 24px;
      }
    }

    &.is-active {
      padding: 10px 20px 8px;
    }
  }
}

@media (min-width: 768px) {
  .download-tabs__control + .download-tabs__control:not(.is-active) {
    border-left: 1px solid $color-heather;
    border-right: none;
  }

  .download-tabs__control.is-active + .download-tabs__control:not(:last-child) {
    border-right: 1px solid $color-heather;
    border-left: none;
  }

  .download-tabs__control.is-active + .download-tabs__control:not(.is-active) {
    border-left: none;
    border-right: none;
  }
}

@include vp-767 {
  .download-tabs__control + .download-tabs__control:not(.is-active) {
    border-top: 1px solid $color-heather;
    border-bottom: none;
  }

  .download-tabs__control.is-active + .download-tabs__control:not(:last-child) {
    border-bottom: 1px solid $color-heather;
    border-top: none;
  }

  .download-tabs__control.is-active + .download-tabs__control:not(.is-active) {
    border: none;
  }
}

.download-tabs__element {
  width: 100%;
}

.download-tabs__element-form {
  margin-bottom: 55px;
  width: max-content;

  @include vp-767 {
    width: 100%;
  }

  .custom-input label input {
    width: 348px;
    background-color: $color-default-white;
    border-radius: 10px;
    padding: 16px 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: $color-bismark;

    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px $color-default-white;
      -webkit-text-fill-color: $color-bismark;
    }

    @include vp-767 {
      width: 100%;
    }
  }

  .custom-input label input:focus {
    background-color: $color-default-white;
  }

  .form-simple__status {
    display: none;
  }

  .form-simple button.btn--purple {
    background: $color-heliotrope;
    border-radius: 10px;
    padding: 15px 20px;
    min-width: 84px;
    min-height: 52px;
    color: $color-default-white;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    margin-left: 8px;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-blue-violet;
        background-color: $color-mauve;
      }
    }

    @include vp-767 {
      margin-left: auto;
    }
  }

  .form-simple.is-success {
    .btn--purple {
      display: none;
      color: $color-silver-tree;
      background-color: $color-transparent;

      &:before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9.5' cy='10' r='9.5' fill='%235FCA89'/%3E%3Cpath d='M5.75793 9.42057L9.21248 13.4509L14.3943 6.25391' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 19px;
        height: 19px;
        margin-right: 10px;
      }
    }

    .form-simple__status {
      display: flex;
    }
  }

  .custom-input__error {
    bottom: -5px;
  }
}

.download-tabs__element-info {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;

  p {
    color: $color-haiti;
  }
}

.download-tabs__link {
  display: inline-flex;

  @include vp-767 {
    display: flex;
    justify-content: center;

    svg {
      width: 170px;
      height: 55px;
    }
  }
}
