.functions-list {
  list-style: none;
  margin: 0 0 14px 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  @include vp-1079 {
    margin: 0 0 5px 0;
  }

  @include vp-1023 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include vp-767 {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    color: $color-haiti;
    margin: 0 0 16px 0;

    @include vp-1079 {
      margin: 0 0 14px 0;
    }

    @include vp-1023 {
      margin: 0 14px 14px 0;
    }

    p {
      display: block;
      max-width: 231px;
      margin: 0 0 0 24px;
      padding: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $color-haiti;
      font-family: $ff-ibm-plex-sans;

      @include vp-1279 {
        font-size: 16px;
        line-height: 19px;
        max-width: 185px;
        margin: 0 0 0 15px;
      }

      @include vp-1023 {
        font-size: 14px;
        line-height: 17px;
        max-width: 161px;
        margin: 0 0 0 8px;
      }

      @include vp-767 {
        font-size: 16px;
        line-height: 19px;
        max-width: 186px;
        margin: 0 0 0 14px;
      }
    }

    svg {
      fill: none;
      width: 20px;
      height: 20px;

      @include vp-1079 {
        width: 18px;
        height: 18px;
      }
    }
  }
}
