.main-nav {

  margin-left: auto;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp-1023 {
      height: 0;
      padding: 17px 32px 35px;
      overflow: hidden;
      position: absolute;
      z-index: 10;
      top: 36px;
      left: 0;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      opacity: 0;
      transition: opacity $trans-default;
    }
  }

  &__link {
    color: $color-default-black;
    font-size: 16px;
    line-height: 21px;
    font-family: $ff-ibm-plex-sans;
    font-weight: 500;

    &.is-active {
      opacity: 0.6;
    }

    &:hover,
    &:focus {
      color: $color-royal-blue;
    }
  }

  &__toggle {
    display: none;
    color: $color-haiti;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include vp-1023 {
      display: block;
      cursor: pointer;
      border: none;
      background-color: transparent;
      transition: color $trans-default;

      &:active {
        color: $color-royal-blue;
      }

      @media (hover: hover),
      screen and (min-width: 0\0) {

        &:hover,
        &:active {
          color: $color-royal-blue;
        }

      }
    }
  }

  &__toggle-close {

    @include vp-1023 {
      display: none;
    }
  }
}
