.btn {
  display: inline-flex;
  padding: 15px 20px;
  color: $color-default-white;
  font-family: $ff-ibm-plex-sans;
  background-color: transparent;
  font-size: 18px;
  line-height: 130%;
  font-weight: 700;
  border: none;
  cursor: pointer;

  transition: opacity $trans-default, color $trans-default, background-color $trans-default, border-color $trans-default;

  &--red {
    background-color: $color-torch-red;
  }

  &:disabled {
    color: $color-heather;

    pointer-events: none;
  }
}

.btn--purple {
  color: $color-heliotrope;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  &:disabled {
    color: $color-pattens-blue;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      color: $color-medium-purple;
    }

    &:active {
      color: $color-blue-violet;
    }
  }
}

.btn--bg {
  background-color: $color-heliotrope;
  border-radius: 10px;
  padding: 16px 22px;

  @include vp-1023 {
    padding: 13px 56px;
  }

  &:disabled {
    color: $color-pattens-blue;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      background-color: $color-medium-purple;
    }

    &:active {
      background-color: $color-blue-violet;
    }
  }
}

.btn--black-bg {
  background-color: $color-haiti;
  color: $color-default-white;
  border-radius: 10px;
  padding: 10px 20px;

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover {
      background-color: $color-medium-purple;
    }
  }
}

.btn--border {
  border: 2px solid $color-cornflower-blue;
  border-radius: 10px;

  &:disabled {
    border-color: $color-heather;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      border-color: $color-jordy-blue;
    }

    &:active {
      border-color: $color-hawkes-blue-2;
    }
  }
}

.btn--violet-border {
  color: $color-heliotrope;
  border: 2px solid $color-heliotrope;
  border-radius: 10px;

  &:disabled {
    color: $color-heather;
    border-color: $color-heather;
  }

  &:active {
    color: $color-blue-violet;
    border-color: $color-blue-violet;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      color: $color-blue-violet;
      border-color: $color-blue-violet;
    }

    &:active {
      color: $purple-heart;
      border-color: $purple-heart;
    }
  }
}

.btn--white {
  padding: 0;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  color: $color-default-white;
}

.btn--dark {
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  font-family: $ff-ibm-plex-sans;
  color: $color-haiti;

  @include vp-1079 {
    font-size: 14px;
    line-height: 18px;
  }
}

.btn--blue {
  background-color: $color-royal-blue;

  &:active {
    background-color: $color-oxford-blue;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      background-color: $color-cornflower-blue;
    }

    &:active {
      background-color: $color-oxford-blue;
    }
  }
}

.btn--view-more {
  align-items: baseline;
  font-size: 18px;
  line-height: 120%;
  padding: 13px 20px;
  font-family: $ff-ibm-plex-sans;
  font-weight: 700;
  color: $color-blue-violet;
  background-color: transparent;
  border: 2px solid $color-heliotrope;
  border-radius: 10px;

  &:active {
    color: $purple-heart;
    border: 2px solid $purple-heart;
  }

  @include vp-1023 {
    font-size: 16px;
  }

  @include vp-767 {
    padding: 12px 20px;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      color: $color-blue-violet;
      border-color: $color-blue-violet;
    }

    &:active {
      color: $purple-heart;
      border-color: $purple-heart;
    }
  }
}

.btn--time-active {
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  font-family: $ff-ibm-plex-sans;
  color: $color-default-white;
  padding: 4px 24px 8px;
  background-color: $color-royal-blue;
  border-radius: 16px;

  @include vp-1079 {
    font-size: 24px;
    line-height: 29px;
  }

  &:active {
    background-color: $color-oxford-blue;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover {
      background-color: $color-cornflower-blue;
    }

    &:active {
      background-color: $color-oxford-blue;
    }
  }
}

.btn--time {
  background-color: transparent;
  padding: 4px 23px 8px;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  font-family: $ff-ibm-plex-sans;
  color: $color-heather;
  border-radius: 16px;

  @include vp-1079 {
    font-size: 24px;
    line-height: 31px;
  }

  &:active {
    opacity: 1;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover {
      color: $color-haiti;
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }
  }
}

.btn--border-grey {
  font-weight: 500;
  color: rgba($color-haiti, 0.4);
  border-radius: 10px;
  border: 1px solid $color-pattens-blue;

  @media (hover: hover),
  screen and (min-width: 0\0) {

    &:hover,
    &:focus {
      color: $color-haiti;
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }
  }

  .btn__icon {
    margin-right: 12px;
  }
}
