.about {

}

.about__reasons {
  margin-bottom: 120px;

  @include vp-1279 {
    margin-bottom: 100px;
  }

  @include vp-1023 {
    margin-bottom: 72px;
  }

  @include vp-767 {
    margin-bottom: 70px;
  }
}

.about__info-block {
  margin-bottom: 120px;

  &:last-child {
    margin-bottom: 100px;
  }

  @include vp-1279 {
    margin-bottom: 77px;

    &:last-child {
      margin-bottom: 81px;
    }
  }

  @include vp-1023 {
    margin-bottom: 55px;

    &:last-child {
      margin-bottom: 50px;
    }
  }
}

.about__sta {
  margin-bottom: 100px;

  @include vp-1023 {
    margin-bottom: 73px;
  }
}

.about__download-block {
  margin-bottom: 140px;

  @include vp-1279 {
    margin-bottom: 119px;
  }

  @include vp-1023 {
    margin-bottom: 86px;
  }

  @include vp-767 {
    margin-bottom: 33px;
  }
}
