.tariffs-list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: center;

  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 35px;

  @include vp-1023 {
    display: flex;
    flex-direction: column;
    grid-row-gap: 33px;
  }

  &__item {
    position: relative;
    padding: 33px 38px 45px;
    background-color: $color-default-white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    max-width: 373px;

    @include vp-1279 {
      padding: 24px 18px 32px;
    }

    @media (max-width: 1100px) {
      padding: 27px 23px 32px;
    }

    @include vp-1023 {
      margin: 0;
      padding: 24px 23px 13px;
      max-width: 100%;
    }

    @include vp-767 {
      padding: 25px 20px 14px;
    }
  }

  &__item--best {
    box-shadow: 0px 10.5325px 22.5697px rgba(62, 62, 80, 0.25);

    .tariffs-list__button {
      background-color: $color-heliotrope;
      color: $color-default-white;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    font-family: $ff-ibm-plex-sans;
    color: $color-haiti;
    padding: 0;
    margin: 0 0 23px 0;

    @include vp-1279 {
      font-size: 20px;
      line-height: 20px;
      margin: 0 0 16px 0;
    }

    @include vp-1023 {
      font-size: 22px;
      line-height: 22px;
      margin: 0 0 8px 0;
    }

    @include vp-767 {
      font-size: 20px;
      line-height: 20px;
      margin: 0 0 15px 0;
    }
  }

  &__price {
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    font-family: $ff-ibm-plex-sans;
    color: $color-haiti;
    margin: 0 0 8px 0;

    @include vp-1279 {
      font-size: 32px;
      line-height: 32px;
      margin: 0 0 12px 0;
    }

    @include vp-1023 {
      margin: 0 0 12px 0;
    }

    @include vp-767 {
      font-size: 36px;
      line-height: 36px;
      margin: 0 0 8px 0;
    }
  }

  &__price--free {
    margin: 0 0 28px 0;

    @include vp-1279 {
      margin: 0 0 29px;
    }

    @include vp-1023 {
      margin: 1px;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &__duration {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $color-heather;
    font-family: $ff-ibm-plex-sans;
    margin: 0 0 20px 0;

    @include vp-1279 {
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 15px 0;
    }

    @include vp-1023 {
      font-size: 12px;
      line-height: 14px;
      margin: 0 0 16px 0;
    }

    @include vp-767 {
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 14px 0;
    }
  }

  &__functionality {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: $color-heather;
    font-family: $ff-ibm-plex-sans;
    margin: 0 0 20px 0;

    @include vp-1279 {
      font-size: 12px;
      line-height: 16px;
      margin: 0 0 12px 0;
    }
  }

  &__button {
    justify-content: center;
    margin: 0 0 28px 0;
    padding: 13px 32px;

    @include vp-1279 {
      margin: 0 0 19px 0;
      padding: 12px 31px;
    }

    @include vp-1023 {
      width: 224px;
      padding: 14px 50px;
    }

    @include vp-767 {
      width: 100%;
      margin: 0 0 19px 0;
    }

    .font-serif {
      font-weight: 700;
    }
  }



  &__ads-wrapper {
    padding: 24px 0 0 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $color-heather;
      top: 0;
      left: 0;
    }

    @include vp-1079 {
      padding: 21px 0 0 0;
    }

    @include vp-1023 {
      display: flex;
      margin-bottom: 19px;
    }

    @include vp-767 {
      flex-direction: column;
      margin-bottom: 28px;
    }
  }

  &__gallery-adds {
    margin: 0 0 18px 0;

    @include vp-1079 {
      margin: 0 0 18px 0;
    }

    @include vp-1023 {
      margin: 0 35px 0 0;
    }

    @include vp-767 {
      margin: 0 0 16px 0;
    }
  }
}
