.features-item__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 98%;
  color: $color-haiti;
  margin: 0 0 34px;

  .font-serif {
    font-weight: 600;
  }

  @include vp-1279 {
    font-size: 44px;
    margin: 0 0 24px;
  }

  @include vp-1023 {
    font-size: 40px;
    margin: 0 0 24px;
    line-height: 112%;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 100%;
    margin: 0 0 20px;
  }
}

.features-item__wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  &>* {
    width: calc((100% - 25px) / 2);

    @include vp-767 {
      width: 100%;
    }
  }

  @include vp-767 {
    flex-wrap: wrap;
    &> * {
      width: 100%;
    }
  }
}

.features-item__tab {
  display: flex;
  flex-direction: column;
  padding-right: 150px;

  @include vp-1279 {
    padding-right: 50px;
  }

  @include vp-1023 {
    padding-right: 35px;
  }

  @include vp-767 {
    width: 100%;
  }
}

.features-item__tab-link {
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: $color-heather;
  margin: 0 0 16px;
  padding: 10px 0;
  transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    color: $color-haiti;

    .features-item__tab-subtitle {
      display: block;
    }
  }

  a {
    font: inherit;
  }

  @include vp-1279 {
    font-size: 24px;
    line-height: 120%;
    margin: 0;
  }

  @include vp-767 {
    font-size: 20px;
    line-height: 120%;
    padding: 0 0 16px;
  }
}

.features-item__tab-subtitle {
  display: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #546E7A;
  margin-top: 24px;
}

.features-item__tab-content-wrapper {
  position: relative;
  width: 580px;
  min-height: 265px;

  @include vp-767 {
    min-height: 188px;
  }
}

.features-item__tab-sticky-container {
  display: flex;
  align-items: center;
}

.features-item__tab-content {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 24px;
  // width: 580px;
  // min-height: 265px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: $color-haiti;
  opacity: 0;
  transition: outline $trans-600, background-color $trans-600, color $trans-600, all 1s ease-out;
  transform: translateY(100%);
  // box-shadow: 6px -1px 7px #00000029;

  &--blue {
    background-color: #A4C6FE;
  }

  &--green {
    background-color: #C5E7B4;
  }

  &--lilac {
    background-color: #D9B8FF;
  }

  &.active {
    top: 16px;
    right: 16px;
    // z-index: 2;
    opacity: 1;
    transform: translateY(0%);
    // box-shadow: 5px -10px 7px #00000029;

    @include vp-1279 {
      top: 12px;
      right: 12px;
    }

    @include vp-1023 {
      top: 8px;
      right: 8px;
    }

    @include vp-767 {
      right: 0;
    }
  }

  &.show {
    top: 0;
    right: 16px;
    // z-index: 1;
    opacity: 1;
    transform: translateY(0%);
  }

  @include vp-1279 {
    top: 12px;
    right: 12px;
    width: 425px;
    min-height: 194px;
    font-size: 20px;
    border-radius: 15px;
    padding: 18px 21px;

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 7px;
    }
  }

  @include vp-1023 {
    width: 319px;
    min-height: 194px;
    font-weight: 500;
    font-size: 18px;
    line-height: 115%;
  }

  @include vp-767 {
    padding: 17px;
    min-height: 188px;
    max-width: 100%;

    img {
      width: 44px;
      height: 44px;
    }

    &.show,
    &.active {
      top: 0;
      right: 0;
    }
  }
}

.features-item__tab-text {
  margin: 0;
}

@media (max-width: 767px) {
  .features-item__tab-text {
      margin: 0;
      font-size: 14px;
      line-height: 16px;
  }

  .features-item__tab-content svg {
    height: 40px!important;
  }
}
