.container {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 80px;

  @include vp-1279 {
    padding: 0 67px;
  }

  @include vp-1023 {
    padding: 0 45px;
  }

  @include vp-767 {
    padding: 0 20px;
  }
}

.container--footer,
.container--intro {
  padding: 0 70px;

  @include vp-1279 {
    padding: 0 57px;
  }

  @include vp-1023 {
    padding: 0 35px;
  }

  @include vp-767 {
    padding: 0 30px;
  }
}
