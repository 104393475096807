.opened {

  .main-nav__wrapper {
    height: auto;
    max-height: calc(100vh - 90px);
    max-height: calc(100 * var(--vh, 1vh) - 90px);
    overflow-y: auto;
    opacity: 1;
    transition: opacity $trans-default;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: $color-default-white;
    z-index: -1;
    box-shadow: 0px 14px 30px rgba(62, 62, 80, 0.25);
  }

  .main-nav__toggle-open {
    display: none;
  }

  .main-nav__toggle-close {
    display: block;
  }
}
