.companies {
  margin: 10px;
  background-color: $color-default-white;
  border-radius: 20px;
  padding: 35px 0;

  .container {
    padding: 0 70px;

    @include vp-1279 {
      padding: 0 57px;
    }

    @include vp-1023 {
      padding: 0 45px;
    }

    @include vp-767 {
      padding: 0;
    }
  }

  @include vp-1279 {
    padding: 25px 0 20px;
  }

  @include vp-1023 {
    padding: 30px 0 50px;
  }

  @include vp-767 {
    margin: 16px 20px 0;
    padding: 24px 20px 30px;
  }

  &__container {
    width: 100%;
  }

  &__title {
    margin: 0;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    font-family: $ff-ibm-plex-sans;
    color: $color-haiti;

    @include vp-1279 {
      margin-bottom: 22px;
      font-size: 24px;
    }

    @include vp-1023 {
      font-size: 28px;
      margin-bottom: 40px;
    }

    @include vp-767 {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include vp-1023 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-items: center;
      grid-row-gap: 35px;
    }

    @include vp-767 {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 26px;
    }
  }

  &__link {
    margin-right: 83px;
    margin-bottom: 20px;
    transition: opacity $trans-default;

    &:last-child,
    &:nth-child(6n) {
      margin-right: auto;
    }

    @include vp-1279 {
      margin-right: 63px;

      &:last-child,
      &:nth-child(6n) {
        margin-right: 0;
      }

      &--1 {
        img {
          width: 145px;
        }
      }

      &--2 {
        img {
          width: 75px;
        }
      }

      &--3 {
        img {
          width: 90px;
        }
      }

      &--4 {
        img {
          width: 42px;
        }
      }

      &--5 {
        img {
          width: 98px;
        }
      }

      &--6 {
        img {
          width: 122px;
        }
      }
    }

    @include vp-1023 {
      margin-right: 0;
      margin-bottom: 0;

      &--1 {
        img {
          width: 182px;
        }
      }

      &--2 {
        img {
          width: 111px;
        }
      }

      &--3 {
        padding-left: 55px;

        img {
          width: 101px;
        }
      }

      &--4 {
        img {
          width: 52px;
        }
      }

      &--5 {
        padding-top: 10px;

        img {
          width: 137px;
          height: 43px;
        }
      }

      &--6 {
        padding-left: 55px;

        img {
          width: 124px;
        }
      }
    }

    @include vp-767 {
      &--1 {
        img {
          width: 120px;
        }
      }

      &--2 {
        padding-left: 30px;

        img {
          width: 80px;
        }
      }

      &--3 {
        padding-left: 0;

        img {
          width: 66px;
        }
      }

      &--4 {
        padding-left: 30px;

        img {
          width: 36px;
          height: 36px;
        }
      }

      &--5 {
        padding-top: 0;

        img {
          width: 93px;
        }
      }

      &--6 {
        padding-left: 27px;
        padding-bottom: 14px;

        img {
          width: 78px;
        }
      }
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
  }
}

.companies--price {
  background-color: transparent;
  margin: 0;
  padding: 35px 0;

  @include vp-1279 {
    margin: 0;
    padding: 17px 0;
  }

  @include vp-1079 {
    padding: 39px 0;
  }

  @include vp-1023 {
    margin: 0;
    padding: 29px 0 0;
  }

  @include vp-767 {
    margin: 0;
    padding: 33px 20px 0;
  }

  .container {
    padding: 0 80px;

    @include vp-1279 {
      padding: 0 65px;
    }

    @include vp-1023 {
      padding: 0 45px;
    }
  }

  .companies__wrapper {

    @include vp-1079 {
      transform: translate(7px, 7px);
    }

    @include vp-1023 {
      grid-row-gap: 41px;
      transform: translate(36px, 7px);
    }

    @include vp-767 {
      grid-row-gap: 24px;
      transform: translate(7px, 7px);
    }
  }

  .companies__container {
    background-color: $color-pattens-blue;
    border-radius: 20px;
    padding: 36px 58px 38px;

    @include vp-1079 {
      padding: 36px 63px 37px;
    }

    @include vp-1023 {
      padding: 31px 22px 58px;
    }

    @include vp-767 {
      padding: 22px 5px 37px;
      display: flex;
      flex-direction: column;
    }
  }

  .companies__title {

    @include vp-1079 {
      font-size: 32px;
      line-height: 42px;
    }

    @include vp-1023 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 34px;
    }

    @include vp-767 {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 16px;
    }
  }

  .companies__link {
    margin-right: 66px;
    padding: 0;
  }

  .companies__link--1 {
    margin-right: 73px;

    @media (max-width: 1345px) {
      margin-right: 65px;
    }

    @include vp-1079 {
      margin-right: 60px;
    }

    @include vp-767 {
      margin: 0;
    }

    img {
      width: 180px;
      height: auto;

      @include vp-1079 {
        width: 168px;
      }

      @include vp-1023 {
        width: 182px;
      }

      @include vp-767 {
        width: 120px;
      }
    }
  }

  .companies__link--2 {
    // transform: translate(-5px, 0px);
    margin-right: 74px;

    @media (max-width: 1345px) {
      margin-right: 65px;
    }

    @include vp-1079 {
      margin-right: 61px;
    }

    @include vp-767 {
      margin: 0;
    }

    img {
      width: 91px;
      height: auto;

      @include vp-1079 {
        width: 85px;
      }

      @include vp-1023 {
        width: 111px;
      }

      @include vp-767 {
        width: 79px;
      }
    }
  }

  .companies__link--3 {
    // transform: translate(-11px, -1px);
    margin-right: 90px;

    @media (max-width: 1369px) {
      margin-right: 80px;
    }

    @media (max-width: 1345px) {
      margin-right: 65px;
    }

    @include vp-1079 {
      margin-right: 58px;
    }

    @include vp-767 {
      margin: 0;
    }

    img {
      width: 100px;
      height: auto;

      @include vp-1079 {
        width: 98px;
      }

      @include vp-1023 {
        width: 101px;
      }

      @include vp-767 {
        width: 66px;
      }
    }
  }

  .companies__link--4 {
    // transform: translate(-5px, -1px);
    margin-right: 79px;

    @media (max-width: 1345px) {
      margin-right: 65px;
    }

    @include vp-1079 {
      margin-right: 60px;
    }

    @include vp-767 {
      margin: 0;
    }

    img {
      width: 50px;
      height: auto;

      @include vp-1079 {
        width: 48px;
      }

      @include vp-1023 {
        width: 52px;
      }

      @include vp-767 {
        width: 36px;
      }
    }
  }

  .companies__link--5 {
    // transform: translate(-5px, 0);
    margin-right: 73px;

    @media (max-width: 1369px) {
      margin-right: 58px;
    }

    @include vp-1079 {
      margin-right: auto;
    }

    @include vp-1023 {
      margin-right: 58px;
    }

    @include vp-767 {
      margin: 0;
    }

    img {
      width: 126px;
      height: auto;

      @include vp-1079 {
        width: 116px;
      }

      @include vp-1023 {
        width: 137px;
      }

      @include vp-767 {
        width: 93px;
      }
    }
  }

  .companies__link--6 {
    // transform: translate(-9px, -4px);
    margin-right: auto;

    img {
      width: 140px;
      height: auto;
    }

    @include vp-1079 {
      display: none;
    }

    @include vp-1023 {
      display: block;
      width: 124px;
      margin-right: 58px;
    }

    @include vp-767 {
      width: 78px;
      margin: 0;
    }
  }
}
