.sharing {
  position: relative;
  transition: outline $trans-600, background-color $trans-600, color  $trans-600, opacity $trans-600;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
}

.sharing__links {
  display: none;
  padding: 20px 24px 26px;
  position: absolute;
  width: 177px;
  height: 176px;
  left: 0;
  top: 58px;
  background: $color-default-white;
  box-shadow: 0 14px 30px rgba(62, 62, 80, 0.25);
  border-radius: 12px;
  z-index: 2;

  @include vp-1279 {
    left: unset;
    right: 0;
  }
}

.sharing__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sharing input:checked ~ .sharing__links {
  display: block;
}

.sharing__link {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: $color-default-black;
  margin-bottom: 26px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: 8px;
    fill: none;
  }

  a,
  button {
    display: flex;
    align-items: center;
    padding: 0;
    font: inherit;
    color: inherit;
    background-color: $color-transparent;
    border: none;
    box-shadow: none;

    cursor: pointer;
    transition: outline $trans-600,
      background-color $trans-600,
      color $trans-600,
      opacity $trans-600;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  @include vp-767 {
    font-size: 14px;
    margin-bottom: 14px;

    svg {
      width: 20px;
    }
  }
}
