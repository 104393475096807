.info-block {
  &--reverse {
    .info-block__wrapper {
      flex-direction: row-reverse;
    }
  }
}

.info-block__wrapper {
  display: flex;
  justify-content: space-between;

  &>* {
    flex: 0 0 calc((100% - 40px) / 2);
  }

  @include vp-767 {
    flex-wrap: wrap;

    &>* {
      flex: 0 0 100%;
    }
  }
}

.info-block__image-wrapper {
  border-radius: 20px;
  overflow: hidden;
  margin-top: 22px;

  img {
    border-radius: 20px;
  }

  @include vp-1279 {
    margin-top: 3px;
  }

  @include vp-1023 {
    margin-top: 14px;
  }

  @include vp-767 {
    border-radius: 11px;
    margin-top: 0;
    margin-bottom: 40px;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 11px;
    }
  }
}

.info-block__content {

  button {
    margin-top: 36px;
  }
}

.info-block__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  margin: 0 0 12px;
  color: $color-haiti;

  b {
    font-weight: 700;
  }

  @include vp-1279 {
    font-size: 44px;
  }

  @include vp-1023 {
    font-size: 40px;
  }

  @include vp-767 {
    font-size: 24px;
  }
}

.info-block__text {
  line-height: 30px;
  font-weight: 400;
  font-size: 20px;
  //line-height: 130%;
  margin-top: 0;
  color: $color-bismark;

  &:last-child {
    margin-bottom: 0;
  }

  @include vp-1279 {
    font-size: 18px;
  }

  @include vp-1023 {
    font-size: 16px;
    line-height: 120%;
  }
}

.info-block--features {
  .info-block__title {
    margin-bottom: 20px;

    .font-serif {
      font-weight: 700;
    }

    @include vp-1279 {
      margin-bottom: 20px;
    }

    @include vp-767 {
      margin-bottom: 10px;
    }
  }

  .info-block__text {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 0;

    @include vp-1279{
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      margin-bottom: 36px;
    }

    @include vp-1023 {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
    }

    @include vp-767 {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 33px;
    }
  }

  .info-block__image-wrapper {
    margin-top: 13px;

    @include vp-1279 {
      margin-top: 7px;
      border-radius: 15px;

      img {
        border-radius: 15px;
      }
    }
  }

  button {
    padding: 14px 20px;
    border-radius: 15px;

    @include vp-1279 {
      padding: 14px 20px;
      margin-top: 0;
      font-size: 16px;
      line-height: 120%;
      border-radius: 10px;
    }
  }
}
