.password-recovery {

  @include vp-1023 {
    padding-left: 21.4%;
    padding-right: 21.4%;
  }
  .modal__title {
    margin-bottom: 9px;

    @include vp-1279 {
      margin-bottom: 9px;
    }

    @include vp-767 {
      margin-bottom: 9px;
    }
  }

  .modal__subtitle {
    @include vp-767 {
      padding-right: 40%;
    }
  }

  .custom-input {
    margin: 30px 0 7px;
  }

  .form-simple__link {
    padding: 10px 0;

    @include vp-1023 {
      padding: 20px 0;
    }
  }

  .modal-sign__btn {
    @include vp-1279 {
      font-size: 16px;
    }

    @include vp-767 {
      padding: 13px;
    }
  }
}
