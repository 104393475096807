.footer-tools {
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.4fr;
  grid-template-rows: auto auto;
  grid-row-gap: 21px;

  @include vp-1279 {
    grid-template-columns: 0.4fr 1fr 0.4fr;
    position: relative;
    grid-row-gap: 19px;
  }

  @include vp-1023 {
    grid-template-columns: 0.3fr 1fr 0.6fr;
    grid-template-rows: auto auto auto;
    grid-row-gap: 19px;
  }

  @include vp-767 {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
    margin: 0 0 18px 0;
  }

  &__logo {
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 170px;
    padding-top: 23px;

    @include vp-1279 {
      padding: 0;
    }

    @include vp-1079 {
      transform: translate(0, -7px);
    }

    @include vp-1023 {
      width: 100%;
      grid-column: 1 / -1;
    }

    @include vp-767 {
      margin: 0 0 18px 0;
    }

    svg {
      color: $color-default-white;
    }

    svg:last-child {
      transform: translate(6px, 2px);
    }
  }

  &__navigation {

    @include vp-1279 {
      grid-column: 2 / -1;
    }

    @include vp-1023 {
      grid-column: 1 / -1;
    }
  }

  &__social {
    padding: 22px 0 0 0;

    @include vp-1279 {
      position: absolute;
      right: 0;
      bottom: 69px;
    }

    @include vp-1023 {
      position: static;
      grid-column: 3 / -1;
      grid-row: 3 / -1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    @include vp-767 {
      width: 88%;
      justify-content: flex-start;
      padding: 0;
    }
  }

  &__social-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 210px;
    margin-left: auto;

    @include vp-1023 {
      transform: translate(-5px, -4px);
    }

    @include vp-767 {
      width: 70%;
      justify-content: space-between;
      margin: 0;
      transform: translate(0, 0);
    }
  }

  &__social-item {
    margin: 0 0 0 16px;

    @include vp-1023 {
      margin: 0 0 0 19px;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &__social-link {
    display: block;
    color: $color-heliotrope;
    transition: color $trans-default;

    svg {
      fill: $color-default-white;

      @include vp-1023 {
        width: 28px;
        height: 28px;
      }
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover {
        color: $color-medium-purple;
      }

      &:active {
        color: $color-blue-violet;
      }
    }
  }

  &__lang-select {
    display: none;

    @include vp-767 {
      display: block;
      width: 100%;
    }
  }

  &__lang-list {
    width: 100%;
    grid-column: 1 / -1;
    grid-row: 2 / -1;

    @include vp-1023 {
      grid-column: 1 / 3;
      grid-row: 3 / -1;
    }

    @include vp-767 {
      display: none;
    }
  }
}
