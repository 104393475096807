.links-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    display: block;
    font-size: 16px;
    line-height: 21px;
    font-family: $ff-ibm-plex-sans;
    font-weight: 500;
    transition: color $trans-default;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include vp-1079 {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
