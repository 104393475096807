.custom-input,
.custom-textarea {
  position: relative;
  padding-bottom: 20px;

  display: flex;

  label {
    display: flex;
    flex-direction: column;
    width: 100%;

    input,
    textarea {
      flex-grow: 1;
      min-height: 56px;
      padding: 10px 18px;

      border-radius: 10px;
      border: none;
      background-color: $color-alice-blue;
      outline: none;
      transition: opacity $trans-default;

      @include vp-767 {
        min-height: 48px;
        padding: 0 18px;
      }
    }

    input:-webkit-autofill {
      box-shadow: inset 0 0 0 1000px $color-alice-blue;
    }

    input::placeholder {
      font-weight: 500;
      color: $color-bismark;
    }
  }

  &__label {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: 3px;

    color: $color-royal-blue;

    opacity: 0;

    transition: opacity $trans-default;
  }

  &.is-invalid {
    input {
      color: $color-royal-blue;
      transition: opacity $trans-default;
      box-shadow: inset 0 0 0 2px $color-royal-blue;

      &:focus,
      &:hover {
        opacity: 0.8;
      }
    }

    input::placeholder {
      color: $color-royal-blue;
    }

    .custom-input__error {
      opacity: 1;
    }
  }

  input:hover,
  input:hover::placeholder,
  textarea:hover,
  textarea:hover::placeholder,
  input:focus,
  input:focus::placeholder,
  textarea:focus,
  textarea:focus::placeholder {
    opacity: 0.8;
  }

  /*&.is-valid {
    input {
      border: 2px solid $color-malachite;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-malachite, 0.5);
      }
    }
  }*/
}
