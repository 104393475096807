.read-also {
  margin: 0 10px;
  padding: 100px 0 122px;
  background-color: $color-alice-blue;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @include vp-1279 {
    padding: 60px 0 134px;
  }

  @include vp-1023 {
    padding: 40px 0 82px;
    margin: 0 10px;
  }

  @include vp-767 {
    padding: 32px 0 59px;
    margin: 0 10px;
  }
}

.read-also__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  color: $color-haiti;
  margin: 0 0 57px;

  @include vp-1279 {
    font-size: 44px;
    margin-bottom: 45px;
  }

  @include vp-1023 {
    font-size: 48px;
    margin-bottom: 38px;
  }

  @include vp-767 {
    font-size: 24px;
    margin-bottom: 28px;
  }
}

.read-also__container {
  margin: 0 auto;
  width: calc(100% + 20px);
  max-width: 890px;

  @include vp-1279 {
    margin: 0 -10px;
  }

  @include vp-1023 {
    margin: 0 -13px;
    width: calc(100% + 26px);
  }

  @include vp-1023 {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }

  @include vp-767 {
    padding: 0 10px;
    width: 100%;
    margin: 0;
  }
}

.read-also__cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  margin-right: auto;
  max-width: 786px;
  row-gap: 70px;
  justify-content: space-between;

  & .card {
    width: calc((100% - 40px)/2);
  }

  @include vp-1279 {
    max-width: unset;
    column-gap: 35px;
    row-gap: 60px;

    & .card {
      width: calc((100% - 35px)/2);
    }
  }

  @include vp-1023 {
    column-gap: 40px;
    row-gap: 37px;

    & .card {
      width: calc((100% - 40px)/2);
    }
  }

  @include vp-767 {
    row-gap: 12px;

    & .card {
      width: 100%;
    }

    .card__image-wrapper {
      height: 212px;
    }

    .card__tag {
      font-weight: 600;
      font-size: 12px;
      line-height: 130%;
    }

    .card__title {
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      margin-bottom: 10px;
    }

    .card__content-info {
      margin-bottom: 12px;
    }
  }
}
