.language {
  display: flex;
  flex-direction: column;

  @include vp-1023 {
    transform: translate(-4px, -3px);
  }

  span {
    color: $color-default-white;
    font-size: 14px;
    line-height: 18px;
    font-family: $ff-ibm-plex-sans;
    font-weight: 700;
    margin: 0 0 12px 0;
    transform: translateX(-5px);
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 422px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__item:not(:last-child) {
    margin: 0 20px 0 0;

    @include vp-1023 {
      margin: 0 21px 0 0;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 18px;
    font-family: $ff-ibm-plex-sans;
    font-weight: 500;
    color: $color-default-white;
    opacity: 0.6;
    transition: opacity $trans-default;

    &--active {
      opacity: 1;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
