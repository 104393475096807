.sta-block {
  position: relative;
  padding: 62px 0 31px;
  overflow: hidden;
  min-height: 425px;

  &__deco-ellipse {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    right: calc(50% - 50px);
    z-index: 1;
    color: $color-cornflower-blue;

    @include vp-1279 {
      right: calc(43% - 46px);
    }

    @include vp-1023 {
      right: calc(27% - 50px);
    }

    @include vp-767 {
      right: calc(10% - 48px);
    }
  }

  &--main {
    margin-bottom: 85px;

    @include vp-1279 {
      margin-bottom: 70px;
    }

    @include vp-767 {
      margin-bottom: 58px;
    }
  }

  &--bg-green {
    .sta-block__wrapper {
      background-color: $color-mantis;
    }

    .sta-block__deco-ellipse {
      color: $color-mauve;
    }

    .sta-block__deco {
      &--1 {
        color: $color-cornflower-blue;
      }

      &--2 {
        color: $color-mauve;
      }

      &--3 {
        color: $color-cornflower-blue;
      }
    }

    .sta-block__btn {
      background-color: $color-heliotrope;

      &:hover {
        background-color: $color-medium-purple;
      }
    }
  }

  &--bg-blue {
    .sta-block__wrapper {
      background-color: $color-cornflower-blue;
    }

    .sta-block__deco-ellipse {
      color: $color-cornflower-blue;
    }

    .sta-block__deco {
      &--1 {
        color: $color-tea-green;
      }

      &--2 {
        color: $color-mauve;
      }

      &--3 {
        color: $color-royal-blue;
      }
    }

    .sta-block__btn {
      background-color: $color-heliotrope;
    }
  }

  &--bg-light-green {
    .sta-block__wrapper {
      background-color: $color-madang;
    }

    .sta-block__deco-ellipse {
      color: $color-pale-cornflower-blue;
    }

    .sta-block__deco {
      &--1 {
        color: $color-cornflower-blue;
      }

      &--2 {
        color: $color-mauve;
      }

      &--3 {
        color: $color-heliotrope;
      }
    }

    .sta-block__btn {
      background-color: $color-royal-blue;
    }
  }

  &--bg-lilac {

    .sta-block__deco-ellipse {
      color: $color-cornflower-blue;
    }


    .sta-block__wrapper {
      background-color: $color-mauve;
    }

    .sta-block__deco {
      &--1 {
        color:$color-madang;
      }

      &--2 {
        color: $color-cornflower-blue;
      }

      &--3 {
        color: $color-heliotrope;
      }
    }
  }
}

.sta-block__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-heliotrope;
  border-radius: 20px;
  min-height: 332px;
  overflow: hidden;

  @include vp-767 {
    border-radius: 16px;
  }
}

.sta-block__deco {
  position: absolute;

  &--1,
  &--2 {
    color: $color-mauve;
  }

  &--3 {
    color: $color-blue-violet;
  }

  &--1 {
    right: calc(87% - 284px);
    bottom: calc(20% - 16px);
  }

  &--2 {
    left: calc(100% - 619px);
    top: calc(100% - 252px);
  }

  &--3 {
    top: calc(50% + 13px);
    left: calc(50% + 37px);
    transform: translate(-50%, -50%);
  }

  @include vp-1279 {
    &--1 {
      right: calc(85% - 270px);
      bottom: calc(13% - 16px);
    }

    &--2 {
      left: calc(100% - 550px);
      top: calc(100% - 270px);
    }

    &--3 {
      top: calc(50% - 38px);
      left: calc(50% + 36px);
      transform: translate(-50%, -50%);
      width: 351px;
    }
  }

  @include vp-1023 {
    &--1 {
      right: calc(85% - 227px);
      bottom: calc(11% - 16px);
      width: 570px;
    }

    &--2 {
      left: calc(100% - 350px);
      top: calc(100% - 357px);
      width: 558px;
    }

    &--3 {
      top: calc(50% - 28px);
      left: calc(50% + 39px);
      transform: translate(-50%, -50%);
      width: 315px;
    }
  }

  @include vp-767 {
    &--1 {
      right: calc(25% - 27px);
      bottom: calc(11% + 75px);
      width: 470px;
    }

    &--2 {
      left: calc(100% - 198px);
      top: calc(100% - 382px);
      width: 441px;
    }

    &--3 {
      top: calc(50% - 26px);
      left: calc(50% - 20px);
      transform: translate(-50%, -50%);
      width: 240px;
    }
  }
}

.sta-block__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-default-white;

  @include vp-767 {
    justify-content: flex-start;
    padding: 50px 20px;
  }
}

.sta-block__title {
  display: inline-block;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  margin: 24px 0 18px;
  z-index: 2;
  text-align: center;

  span {
    display: inline-block;
  }

  @include vp-767 {
    font-size: 32px;
    margin: 0 0 20px;
  }
}

.sta-block__text {
  margin: 0 0 36px;
  z-index: 2;
  text-align: center;

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    margin: 0;
    z-index: 2;
  }

  @include vp-1279 {
    margin: 0 0 26px;
  }


  @include vp-767 {
    margin: 0 0 16px;

    p {
      font-size: 20px;
    }

    br {
      display: none;
    }
  }
}

.sta-block__btn {
  z-index: 2;
  padding: 14px 21px;
  margin-left: 20px;

  @include vp-1279 {
    font-size: 16px;
    margin-left: 0;
    margin-bottom: 15px;
  }

  @include vp-1023 {
    padding: 14px 21px;
  }

  @include vp-767 {
    margin-bottom: 0;
  }
}

.sta-block--price {

  .sta-block__wrapper {
    background-color: $color-jordy-blue;
  }

  .sta-block__deco--1,
  .sta-block__deco--3 {
    color: $color-royal-blue;
    fill: $color-royal-blue;
  }

  .sta-block__content .btn--bg {
    background-color: $color-heliotrope;

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        background-color: $color-medium-purple;
      }

      &:active {
        background-color: $color-blue-violet;
      }
    }
  }
}

.sta-block--features {

  .sta-block__wrapper {
    @include vp-767 {
      left: 10px;
    }
  }

  .sta-block__deco-ellipse {
    @include vp-767 {
      right: calc(10% - 56px);
    }
  }
}
