.form-field {
  position: relative;
  padding-bottom: 29px;

  @include vp-767 {
    padding-bottom: 23px;
  }

  &__label {
    margin: 0;
    font-size: 20px;
    line-height: 34px;
  }

  .custom-select__label,
  .custom-input__label,
  .form-field__label {
    color: $color-default-black;
    margin-bottom: 15px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex: 1;
    min-width: 109px;

    @include vp-767 {
      padding-bottom: 20px;
      flex: none;
      min-width: 114px;
    }

    &:last-of-type {
      min-width: 50px;

      @include vp-767 {
        min-width: 114px;
      }

      .custom-toggle {
        justify-content: center;
        padding-right: 12px;

        @include vp-767 {
          justify-content: flex-start;
          padding-right: 0;
        }
      }
    }
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: 3px;
    margin: 0;
    font-size: 14px;
    color: $color-royal-blue;
    opacity: 0;
    transition: opacity $trans-default;
  }

  &.is-invalid {
    .form-field__error {
      opacity: 1;
    }
  }
}
