.guarantees {
  background-color: transparent;
  margin: 0 0 100px 0;

  @include vp-1023 {
    margin: 0 0 110px 0;
  }

  @include vp-767 {
    margin: 0 0 70px 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    grid-column-gap: 103px;
    grid-row-gap: 25px;

    @include vp-1023 {
      padding: 0;
      grid-column-gap: 87px;
    }

    @include vp-767 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__item {

    &--safety {

      @include vp-767 {
        order: 1;
        margin: 0 0 17px 0;
      }

      img {
        @include vp-1023 {
          width: 144px;
        }

        @include vp-767 {
          width: 125px;
        }
      }
    }

    &--norton {

      @include vp-767 {
        order: 3;
        margin: 0;
      }

      img {
        @include vp-1023 {
          width: 131px;
        }

        @include vp-767 {
          width: 168px;
        }
      }
    }

    &--satisfaction {

      @include vp-767 {
        order: 2;
        margin: 0 0 35px 0;
      }

      img {
        @include vp-1023 {
          width: 140px;
        }

        @include vp-767 {
          width: 120px;
        }
      }
    }
  }

  &__link {
    display: block;
    pointer-events: none;
  }
}
