:root {
  --swiper-navigation-size: 22px;
}

.avatars-swiper {
  display: flex;
  height: 68px;

  .swiper-button-prev,
  .swiper-button-next {
    position: unset;
    margin-top: 0;
    padding: 16px 0 8px;
    height: unset;
    color: $color-blue-violet;

    @include vp-1023 {
      padding: 12px 0 8px;
    }

    @include vp-767 {
      display: none;
    }
  }

  .swiper-button-prev {
    margin-right: 18px;
  }

  .swiper-slide {
    @include vp-767 {
      width: 74px;
      margin-right: 15px;
    }
  }
}
