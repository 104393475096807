[data-accordion="element"].is-active > [data-accordion="content"] {
  max-height: 100%;
}

[data-accordion="parent"].no-transition [data-accordion="content"] {
  transition: none;
}

[data-accordion="content"] {
  max-height: 0;
  transition: max-height 0.3s;
  overflow: hidden;
}

[data-accordion="element"].is-active > .features__img {
  opacity: 1;
  z-index: 2;
}
