.intro-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 56px 5.2%;
  color: $color-default-white;
  background-color: $color-cornflower-blue;
  border-radius: 20px;
  min-height: 100%;

  font-size: 18px;
  font-weight: 500;
  overflow: hidden;

  @include vp-1279 {
    padding-left: 57px;
    padding-right: 18px;
  }

  @include vp-1023 {
    padding: 32px 36px;
    background-color: $color-transparent;
    border-radius: 0;
    color: $color-royal-blue;
  }

  @include vp-767 {
    padding: 34px;
  }

  .modal__logo {
    display: block;
    color: inherit;
  }

  .modal__title {
    color: inherit;
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 500;
  }

  .modal__title,
  .intro-modal__subtitle {
    z-index: 1;
  }

  &__subtitle {
    margin: 0;
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: -140px;
    left: 0;

    display: flex;
    justify-content: flex-end;

    @include vp-1279 {
      right: -22px;
      bottom: -61px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;

      @include vp-1279 {
        width: 411px;
      }
    }
  }

  @include vp-1023 {
    .intro-modal__img,
    .intro-modal__subtitle,
    .modal__title {
      display: none;
    }
  }
}
