.modal-features {
  padding: 58px 72px 0;
  background-color: $color-default-white;
  border-radius: 20px;

  @include vp-1023 {
    padding: 30px 20px 125px;
  }

  @include vp-1023 {
    border-radius: 0;
  }

  @include vp-767 {
    background-color: $color-alice-blue;
  }

  &__title {
    margin: 0;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 130%;
    font-weight: 600;
    color: $color-haiti;

    @include vp-1023 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }

  &__text {
    margin: 0;
    margin-bottom: 48px;
    font-size: 20px;
    line-height: 130%;
    font-weight: 600;
    color: $color-bismark;

    @include vp-1023 {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 20px;
    }
  }

  .features-list {
    .container {
      padding: 0 80px 0 0;

      @include vp-1023 {
        padding: 0;
      }
    }

    &__list {
      margin-top: 0;
    }

    &__item {
      width: 274px;
      margin-top: 0;
      margin-bottom: 65px;

      @include vp-1023 {
        margin-bottom: 32px;
      }

      svg {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;

        @include vp-1023 {
          width: 72px;
          height: 72px;
          margin-bottom: 8px;
        }
      }
    }
  }

  &__buttons  {
    margin-top: -17px;

    @include vp-1023 {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: auto;
      margin-top: 0;
      background-color: $color-alice-blue;
      padding: 28px 20px 19px;
      box-shadow: 4px 0 20px rgba(0, 0, 0, 0.18);
    }

    .btn--view-more {
      margin-bottom: 90px;

      svg {
        margin-left: 8px;
      }

      @include vp-1023 {
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  &__btn-close {
    text-align: center;
    padding: 33px 0;
    margin: 0 -72px;
    border-radius: 0 0 20px 20px;
    background-color: $color-pattens-blue;

    @include vp-1023 {
      margin: 0;
      padding: 0;
      background-color: $color-transparent;
    }

    .btn {
      padding: 9px 20px 8px 16px;
      font-size: 16px;

      svg {
        width: 20px;
        height: 20px;
        margin-left: 15px;
      }
    }
  }
}
