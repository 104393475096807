// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "IBM Plex Sans";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-sans-medium.woff2") format("woff2"),
    url("../fonts/ibm-plex-sans-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "IBM Plex Sans";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-sans-bold.woff2") format("woff2"),
    url("../fonts/ibm-plex-sans-bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "IBM Plex Sans";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-sans-semibold.woff2") format("woff2"),
    url("../fonts/ibm-plex-sans-semibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "IBM Plex Sans";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-sans-regular.woff2") format("woff2"),
    url("../fonts/ibm-plex-sans-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Euclid Circular A";

  font-display: swap;
  src:
    url("../fonts/euclidcirculara-regular.woff2") format("woff2"),
    url("../fonts/euclidcirculara-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Euclid Circular A";

  font-display: swap;
  src:
    url("../fonts/euclidcirculara-italic.woff2") format("woff2"),
    url("../fonts/euclidcirculara-italic.woff") format("woff");
}

@font-face {
  font-style: italic;
  font-weight: 500;
  font-family: "IBM Plex Serif";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-serif-medium-italic.woff2") format("woff2"),
    url("../fonts/ibm-plex-serif-medium-italic.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "IBM Plex Serif";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-serif-semibold.woff2") format("woff2"),
    url("../fonts/ibm-plex-serif-semibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "IBM Plex Serif";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-serif-bold.woff2") format("woff2"),
    url("../fonts/ibm-plex-serif-bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "IBM Plex Serif";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-serif-medium.woff2") format("woff2"),
    url("../fonts/ibm-plex-serif-medium.woff") format("woff");
}

@font-face {
  font-style: italic;
  font-weight: 700;
  font-family: "IBM Plex Serif";

  font-display: swap;
  src:
    url("../fonts/ibm-plex-serif-bolditalic.woff2") format("woff2"),
    url("../fonts/ibm-plex-serif-bolditalic.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/roboto-medium.woff2") format("woff2"),
    url("../fonts/roboto-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Golos";

  font-display: swap;
  src:
    url("../fonts/golostext-regular.woff2") format("woff2"),
    url("../fonts/golostext-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Heebo";

  font-display: swap;
  src:
    url("../fonts/heebo-regular.woff2") format("woff2"),
    url("../fonts/heebo-regular.woff") format("woff");
}

