.modal-sign {
  padding: 94px 15% 50px;
  border-radius: 20px;
  background-color: $color-default-white;

  @include vp-1279 {
    padding: 90px 86px 20px;
  }

  @include vp-1023 {
    padding: 6px 23%;
    padding-right: 20%;
    border-radius: 0;
    background-color: $color-transparent;
  }

  @include vp-767 {
    padding: 0 30px 30px;
  }


  .custom-input {
    @include vp-1023 {
      margin-bottom: 5px;
    }

    @include vp-767 {
      padding-bottom: 17px;
      margin-bottom: 0;
    }
  }

  .custom-input__error {
    @include vp-767 {
      font-size: 12px;
    }
  }

  .password-input {
    margin-bottom: 13px;

    @include vp-767 {
      margin-bottom: 5px;
    }
  }

  .form-simple__link {
    padding: 14px 0;

    @include vp-1279 {
      padding: 18px 0;
    }

    @include vp-1023 {
      padding: 26px 0;
    }

    @include vp-767 {
      padding: 18px 0;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:focus,
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;

    @include vp-767 {
      flex-direction: column;
    }

    .btn {
      flex: 1;
      align-items: center;
      padding: 14px 11px;
      margin-right: 32px;
      font-size: 14px;

      &:last-child {
        margin: 0;
      }

      @include vp-767 {
        justify-content: center;
        margin: 0 0 16px;
        padding: 8px;
      }
    }
  }

  &__sub {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    @include vp-767 {
      font-size: 16px;
    }
  }

  &__link {
    color: $color-royal-blue;
    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  .form-simple {
    font-size: 15px;
    line-height: 1;
    flex-direction: column;
    align-items: stretch;

    @include vp-1023 {
      font-size: 16px;
    }
  }

  .form-simple form {
    flex-direction: column;
    align-items: unset;
  }

  .modal__title {
    margin: 0 0 28px;

    @include vp-1279 {
      margin-bottom: 32px;
    }

    @include vp-767 {
      margin-bottom: 17px;
    }
  }

  .btn__icon {
    width: 21px;
    height: 21px;
  }

  &__btn {
    justify-content: center;

    @include vp-1279 {
      padding-bottom: 12px;
    }

    @include vp-1023 {
      font-size: 16px;
    }

    @include vp-767 {
      width: 100%;
    }
  }
}

.modal-sign--up {
  @include vp-1023 {
    padding: 6px 21.5% 6px 21.5%;
  }

  .modal__title {
    margin: 0 0 23px;

    @include vp-1279 {
      margin-bottom: 24px;
    }

    @include vp-1023 {
      margin-bottom: 25px;
    }

    @include vp-767 {
      margin-bottom: 17px;
    }
  }

  .steps {
    margin-bottom: 32px;

    @include vp-1023 {
      margin-bottom: 33px;
    }

    @include vp-767 {
      margin-bottom: 24px;
    }
  }

  .steps__item {
    @include vp-767 {
      padding-bottom: 8px;
    }
  }

  .divider {
    @include vp-1023 {
      padding: 16px 0 24px;
    }

    @include vp-767 {
      padding: 15px 0;
    }
  }

  .custom-input {
    padding-bottom: 0;
    margin-bottom: 20px;

    @include vp-1023 {
      padding-bottom: 0;
      margin-bottom: 25px;
    }

    @include vp-767 {
      padding-bottom: 0;
      margin-bottom: 21px;
    }
  }

  .password-input {
    padding-bottom: 0;
    margin-bottom: 30px;

    @include vp-1023 {
      padding-bottom: 0;
      margin-bottom: 27px;
    }
  }

  .btn--bg, .btn--black-bg {
    padding: 14px 22px;
    margin-bottom: 20px;
    justify-content: center;

    @include vp-1279 {
      font-size: 16px;
    }

    @include vp-767 {
      width: 100%;
    }
  }

  .form-simple__info {
    // display: flex;
    // flex-wrap: wrap;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: $color-bismark;

    .form-simple__link {
      padding: 0 3px 5px 3px;
      color: $color-royal-blue;
      text-decoration: underline;
      transition: opacity $trans-default;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 0.5;
        }
      }
    }

    @include vp-767 {
      justify-content: center;

      .form-simple__link {
        text-align: center;
        width: 100%;
      }
    }
  }

  .custom-input__error {
    bottom: -15px;

    @include vp-767 {
      font-size: 10px;
      bottom: -11px;
    }
  }
}

.modal-sign--verify {
  .modal__title {
    margin-bottom: 20px;
  }

  .form-simple__input-group {
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    margin-bottom: 16px;

    @include vp-767 {
      max-width: 281px;
      margin-bottom: 13px;
    }
  }

  .modal-sign__title {
    margin: 0 0 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
  }

  .modal-sign__text {
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: $color-bismark;

    @include vp-767 {
      font-size: 14px;
      line-height: 16.8px;
      margin-bottom: 12px;
    }

  &:last-child {
    margin-bottom: 0;
  }
  }

  .steps {
    margin-bottom: 29px;

    @include vp-767 {
      margin-bottom: 14px;
    }
  }

  .code-input {
    width: 36px;
    height: 49px;
    flex: unset;
    margin-right: 12px;
    margin-bottom: 0;

    @include vp-767 {
      width: unset;
      flex: 1;
      max-width: 36px;
      margin-right: auto;
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    input {
      min-height: unset;
      padding: 8px;
      background-color: $color-transparent;
      border: 1px solid #B5C2CA;
      border-radius: 6px;
      text-align: center;
    }
  }
}
