.price-adds {
  display: grid;
  grid-template-columns: 0.7fr 1.2fr;
  grid-template-rows: auto auto;
  grid-column-gap: 13px;
  margin-bottom: 20px;

  @include vp-1079 {
    grid-template-columns: 0.5fr 1fr;
    grid-column-gap: 15px;
  }

  @include vp-1023 {
    grid-template-columns: 0.7fr 1.1fr;
    width: 37%;
  }

  @include vp-767 {
    width: 90%;
  }

  &__title {
    margin: 0 0 19px 0;
    padding: 0;
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-family: $ff-ibm-plex-sans;
    color: $color-heliotrope;
    width: 100%;
    grid-column: 1 / -1;
    grid-row: 1 / 2;

    @include vp-1279 {
      font-size: 13px;
      line-height: 16px;
      margin: 0 0 13px 0;
    }

    @include vp-767 {
      margin: 0 0 14px 0;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-family: $ff-ibm-plex-sans;
    color: $color-haiti;

    span:first-of-type {
      display: block;
      margin: 0 0 12px 0;
    }

    span:last-of-type {
      display: block;
    }

    @include vp-1279 {
      font-size: 11px;
      line-height: 15px;
    }
  }
}

.price-adds--screen {

  .price-adds__title {

    @include vp-1023 {
      margin: 0 0 5px;
    }

    @include vp-767 {
      margin: 0 0 14px;
    }
  }
}
