.download-block {
  &--main {
    margin-bottom: 115px;

    .font-serif--italic {
      margin-left: 0;
    }

    @include vp-1279 {
      margin-bottom: 100px;
    }

    @include vp-1023 {
      margin-bottom: 90px;
    }

    @include vp-767 {
      margin-bottom: 35px;
    }

    .download-block__title {

      @include vp-767 {
        font-size: 24px;
      }
    }
  }

  &--price {
    padding: 72px 0 0;
    margin: 0 0 82px;

    @include vp-1079 {
      padding: 68px 0 0;
      margin: 0 0 81px;
    }

    @include vp-1023 {
      margin: 0 0 58px;
      padding: 72px 0 0;
    }

    @include vp-767 {
      padding: 40px 0 0;
      margin: 0 0 23px;
    }

    .download-block__title {
      margin: 0 0 45px;

      .font-serif--italic {

        @include vp-767 {
          margin-left: 0;
        }
      }

      @include vp-1079 {
        margin: 0 0 38px;
      }

      @include vp-1023 {
        font-size: 28px;
        line-height: 36px;
        margin: 0 0 59px;
      }

      @include vp-767 {
        font-size: 24px;
        line-height: 31px;
        margin: 0 0 78px;
      }
    }

    .download-block__apps {
      transform: translate(-19px, 1px);

      @include vp-1079 {
        transform: translate(0, 0);
      }
    }

    .download-block__app {
      margin-left: 0;
      margin-left: 43px;

      @include vp-1023 {
        margin-left: 39px;
      }

      @include vp-767 {
        margin-left: 0;
      }
    }
  }

  &--features {
    .download-block__apps {
      margin-left: -75px;

      @include vp-1279 {
        margin: -53px 0 0 -44px;
      }

      @include vp-1023 {
        margin: -21px 0 0 -54px;
      }

      @include vp-767 {
        margin: 0;
      }
    }

    .download-block__app {
      margin-left: 40px;
      margin-top: 20px;

      @include vp-1279 {
        margin-left: 43px;
      }

      @include vp-1023 {
        margin-left: 38px;
      }

      @include vp-767 {
        margin: 24px 0 0;
        top: unset;
      }
    }

    .download-block__title {
      margin: 0 0 67px;

      @include vp-767 {
        margin: 0 0 0 12px;
      }
    }
  }
}

.download-block__title {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  margin: 0 0 50px;

  @include vp-1279 {
    margin: 0 0 35px;
  }

  @include vp-1023 {
    margin: 0 0 64px;
  }

  @include vp-767 {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    margin: 0 0 78px;
  }
}

.download-block__apps {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: -40px 0 0 -18px;

  @include vp-1279 {
    margin: -40px 0 0 -43px;
  }

  @include vp-1023 {
    margin: -40px 0 0 -53px;
  }

  @include vp-767 {
    flex-direction: column;
    margin: -40px 0 0 -16px;
  }
}

.download-block__app {
  margin-left: 43px;
  margin-top: 40px;
  position: relative;

  a {
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;
    color: $color-default-black;
    transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        color: $color-blue-violet;
        transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;
      }
    }
  }

  .mark {
    position: absolute;
    top: -9px;
    right: -7px;

    @include vp-1279 {
      top: -14px;
      right: -8px;
    }

    @include vp-767 {
      top: -9px;
      right: -7px;
    }
  }

  @include vp-1023 {
    margin-left: 38px;
    top: -14px;
    right: -8px;
  }

  @include vp-767 {
    margin: 0 0 24px;

    a > svg {
      width: 172px;
      height: 54px;
    }
  }
}
