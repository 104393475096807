.tabs-element {
  background-color: $color-solitude;
  border-radius: 20px;
  padding: 44px 33px;
  width: 100%;
  position: relative;
  color: $color-haiti;
  min-height: 770px;

  @include vp-1279 {
    padding: 40px 33px;
  }

  @include vp-1023 {
    padding: 39px 33px 30px;
  }

  @include vp-767 {
    padding: 40px 20px 6px;
  }
}

.tabs-element__title {
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
  margin: 0 0 36px;

  @include vp-1023 {
    margin: 0 0 34px;
  }

  @include vp-767 {
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    margin: 0 0 22px;
  }
}

.tabs-element__wrapper {
  padding: 0 11px;
  max-width: 790px;
  margin-bottom: 24px;

  @include vp-1279 {
    max-width: calc(100% - 250px);
    margin-bottom: 14px;
  }

  @include vp-1023 {
    max-width: calc(100% - 195px);
    margin-bottom: 0;
  }

  @include vp-767 {
    padding: 140px 0 0;
    max-width: 100%;
  }
}

.tabs-element__app-link {
  position: absolute;
  top: 53px;
  right: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 30.1053px;
    line-height: 130%;
    margin: 0 0 44px;
  }

  a {
    position: relative;
    margin-bottom: 26px;
    transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;

    & .mark {
      position: absolute;
      top: -14px;
      right: -8px;

      svg {
        width: 28px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-royal-blue;
        transition: outline $trans-600, background-color $trans-600, color $trans-600, opacity $trans-600;
      }
    }
  }

  img {
    position: relative;
    left: 16px;
  }

  @media (min-width: 1300px) {
    top: 53px;
    right: 56px;
  }

  @include vp-1279 {
    top: 55px;
    right: 38px;
  }

  @include vp-1023 {
    top: 49px;
    right: 36px;

    a {
      margin-bottom: 14px;

      svg {
        width: 175px;
      }
    }

    .mark.mark--beta.mark--xl {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    img {
      width: 175px;
      left: 13px;
    }

    p {
      margin-bottom: 16px;
    }
  }

  @include vp-767 {
    top: 38px;
    left: 20px;
    align-items: flex-start;

    img {
      display: none;
    }

    a svg {
      width: 195px;
      height: auto;
    }

    .mark.mark--beta.mark--xl {
      svg {
        width: 17px;
        height: 17px;
      }
    }

    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 130%;
      margin: 0 0 19px;
    }
  }
}

.tabs-element__features-accent {
  display: flex;
  flex-direction: column;
  b {
    margin-top: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: $color-royal-blue;
  }
}

.tabs-element__features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tabs-element__features-item {
  margin-bottom: 40px;
  width: 50%;
  padding-left: 3px;

  br {
    display: none;
  }

  &:nth-child(2) {
    width: 47%;
    padding-left: 14px;
  }

  &:nth-child(3) {
    width: 100%;
    border-top: 1px solid $color-heather;
    padding-top: 44px;
    margin-bottom: 0;

    .tabs-element__features-list {
      column-count: 2;
      column-gap: 80px;
    }
  }

  @include vp-1279 {
    width: 100%;
    margin-bottom: 37px;

    &:nth-child(2) {
      width: 100%;
      padding-left: 3px;
    }

    &:nth-child(3) {
      width: 100%;
      padding-top: 40px;

      .tabs-element__features-list {
        column-count: 1;
        column-gap: 0;
      }
      br {
        display: block;
      }
    }
  }

  @include vp-1023 {
    margin-bottom: 35px;

    &:nth-child(2) {
      padding-bottom: 10px;
      margin-bottom: 30px;
    }

    &:nth-child(3) {
      padding-top: 35px;

      .tabs-element__features-title {
        margin-bottom: 30px;
      }

      .tabs-element__features-list {
        padding-right: 37px;
      }

      .tabs-element__features-list-item {
        padding: 0;
        margin: 0 0 19px;

        &:before {
          margin: 2px 16px 0 0;
        }
      }
    }
  }

  @include vp-767 {
    padding-left: 0;
    margin-bottom: 20px;

    &:first-child {
      .tabs-element__features-title {
        font-size: 20px;
      }
    }

    &:nth-child(2) {
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    &:nth-child(3) {
      .tabs-element__features-title {
        margin-bottom: 26px;
      }

      .tabs-element__features-list {
        padding-right: 0;
      }

      .tabs-element__features-list-item {
        padding: 0;
        margin: 0 0 21px;
      }
    }
  }
}

.tabs-element__features-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  margin: 0 0 12px;
  width: 100%;

  @include vp-1023 {
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    margin: 0 0 14px;
  }

  @include vp-767 {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 24px;
  }
}

.tabs-element__features-list {
  list-style: none;
  padding: 0;
  margin: 0;

  @include vp-1279 {
    padding-right: 55px;
  }

  @include vp-767 {
    padding-right: 0;
  }
}

.tabs-element__features-list-item {
  display: flex;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 10px;
  padding-top: 10px;
  break-inside: avoid;

  &::before {
    flex-shrink: 0;
    content: "";
    display: block;
    background-color: $color-royal-blue;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.57568 5.74089L6.12114 11.0439L12.9393 1.57422' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin: 4px 16px 0 0;

    @include vp-1023 {
      margin: 0 16px 0 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include vp-1023 {
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
  }

  @include vp-767 {
    font-size: 16px;
    padding: 0;
    margin: 15px 0;

    &::before {
      width: 18px;
      height: 18px;
      margin: 2px 16px 0 0;
      background-size: 10px;
    }
  }
}
