.messengers {
  &__wrapper {
    display: flex;
    background-color: $color-pattens-blue;
    border-radius: 20px;
    padding: 40px;
    min-height: 238px;

    &> * {
      width: calc(100% / 2);
    }

    @include vp-1023 {
      flex-wrap: wrap;

      &> * {
        width: 100%;
      }
    }

    @include vp-767 {
      padding: 20px;
      min-height: unset;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    padding-right: 90px;

    @include vp-1279 {
      font-size: 44px;
    }

    @include vp-1023 {
      font-size: 40px;
      margin-bottom: 20px;
    }

    @include vp-767 {
      font-size: 24px;
      margin-bottom: 16px;
    }

    @include vp-359 {
      font-size: 20px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 19px 0;
    margin: -20px 0 3px -20px;

    @include vp-1279 {
      margin: -20px 0 0 0;
    }

    @include vp-1023 {
      margin: -20px 0 0 -20px;
    }

    @include vp-767 {
      margin: -10px 0 0 -10px;
    }
  }

  &__item {
    margin: 20px 0 0 40px;

    @include vp-1279 {
      margin: 20px 0 0 20px;
    }

    @include vp-1023 {
      margin: 20px 0 0 20px;

      svg {
        width: 64px;
        height: 64px;
      }
    }

    @include vp-767 {
      margin: 10px 0 0 10px;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    @include vp-359 {
      margin: 10px 0 0 10px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    opacity: 0.6;
    margin: 0 0 0 20px;

    @include vp-1023 {
      margin: 0;
      font-size: 16px;
    }

    @include vp-767 {
      font-size: 14px;
    }
  }
}
