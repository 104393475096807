.header {
  padding: 40px 0 0;
  z-index: 5;
  transition: height $trans-default;

  @include vp-1023 {
    padding: 30px 0 0;
  }

  @include vp-767 {
    padding: 30px 0 0;
  }

  .container {
    display: flex;
  }

  &__logo {
    display: flex;
    align-items: center;
    width: 168px;
    margin-right: 10px;
    color: $color-royal-blue;

    @include vp-1079 {
      transform: translate(3px, 2px);
      width: 145px;
    }

    @include vp-1023 {
      transform: translate(3px, 1px);
      width: 160px;
    }

    svg {
      stroke: $color-royal-blue;
      fill: $color-royal-blue;
      color: $color-royal-blue;

      @include vp-1079 {
        width: 131px;
        height: 23px;
      }

      @include vp-1023 {
        width: 152px;
        height: 26px;
      }
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $color-default-white;
    padding: 13px 36px;
    border-radius: 15px;

    @include vp-1199 {
      padding: 14px 22px;
    }

    @include vp-1023 {
      position: relative;
      padding: 15px 19px;
    }
  }
}

.header--grey {

  &.opened {

    .main-nav__wrapper {
      background-color: $color-alice-blue;
    }

  }

  .header__wrapper {
    background-color: $color-alice-blue;
  }

  .header-select {
    .custom-select.is-open .custom-select__list {
      background-color: $color-alice-blue;
    }
  }

  .burger-menu__content--about,
  .burger-menu__content--features,
  .burger-menu__content--support {
    background-color: $color-alice-blue;
  }

  .burger-menu__content--about::before,
  .burger-menu__content--features::before,
  .burger-menu__content--support::before {
    border-bottom: 20px solid $color-alice-blue;
  }
}
