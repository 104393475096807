.faq {
  margin: 0 0 72px 0;
  padding: 120px 0 0 0;

  @include vp-767 {
    padding: 72px 0 0 0;
    margin: 0 0 45px 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    grid-row-gap: 35px;

    @include vp-1279 {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__title {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 48px;
    line-height: 120%;
    color: $color-haiti;

    @include vp-1279 {
      margin: 0 0 64px;
      font-size: 44px;
    }

    @include vp-1023 {
      margin: 0 0 44px;
      font-size: 40px;
    }

    @include vp-767 {
      margin: 0 0 32px;
      font-size: 24px;
    }
  }

  &__title-sans {
    font-family: $ff-ibm-plex-sans;
    font-weight: 700;
  }

  &__title-serif {
    display: block;
    font-family: $ff-ibm-plex-serif;
    font-weight: 700;
  }

  &__questions {
    justify-self: end;

    @include vp-1279 {
      margin: 0 0 35px;
    }

    @include vp-1023 {
      margin: 0 0 31px;
    }

    @include vp-767 {
      margin: 0 0 27px;
    }
  }

  &__button {
    justify-self: center;
    grid-column: 1 / -1;
    transform: translate(-25px, -1px);

    @include vp-1279 {
      transform: translate(2px, 0);
    }
  }

  &__button-item {
    display: flex;
    align-items: center;

    @include vp-767 {
      width: 99%;
      justify-content: center;
    }
  }

  &__button-icon {

    svg {
      display: none;
    }

    svg:first-of-type {

      @include vp-1023 {
        display: block;
        margin: 0 5px 0 20px;
      }

      @include vp-767 {
        display: none;
      }
    }

    svg:last-of-type {

      @include vp-767 {
        display: block;
        margin: 0 0 0 10px;
      }
    }
  }
}

.faq--main {
  padding-top: 80px;
  margin-bottom: 78px;

  @include vp-1279 {
    padding-top: 35px;
  }

  @include vp-1023 {
    margin-bottom: 50px;
    padding-top: 70px;
  }

  .faq__wrapper {
    grid-row-gap: 40px;

    @include vp-1023 {
      grid-row-gap: 33px;
    }
  }

  .questions {
    padding-top: 12px;

    @include vp-1279 {
      width: 82.5%;
      margin-bottom: 0;
    }

    @include vp-1023 {
      width: 100%;
      padding-top: 0;
    }

    &__element {
      margin: 0 0 31px;

      @include vp-1023 {
        padding: 28px 0 0;
      }

      @include vp-767 {
        margin: 0 0 14px;
        padding: 16px 0 0;
      }
    }

    &__button-text {
      @include vp-1023 {
        font-size: 26px;
      }

      @include vp-767 {
        font-size: 16px;
      }
    }

    &__button-icon {
      &::before,
      &::after {
        top: -2px;
        right: 0;
        width: 40px;

        @include vp-767 {
          width: 20px;
          top: 0;
          right: 0;
        }
      }
    }
  }

  .faq__title {
    @include vp-1279 {
      font-size: 48px;
      margin-bottom: 5px;
    }

    @include vp-1023 {
      margin-bottom: 0;
    }

    @include vp-767 {
      font-size: 24px;
    }
  }

  .faq__button {
    padding-top: 7px;

    @include vp-767 {
      padding-top: 0;
    }
  }

  .faq__button-item {
    padding: 13px 16px;

    @include vp-1023 {
      padding: 12px 15px;
    }

    @include vp-767 {
      width: 100%;
    }
  }

  .faq__button-icon {

    svg:nth-child(1) {

      @include vp-1023 {
        margin-left: 22px;
        margin-right: 9px;
      }

      @include vp-767 {
        display: block;
      }
    }

    svg:nth-child(2) {

      @include vp-1279 {
        display: block;
        margin-left: 15px;
      }

      @include vp-1023 {
        display: none;
      }
    }
  }
}

.faq--price {
  padding: 85px 0 0;

  @include vp-1079 {
    padding: 56px 0 0;
  }

  @include vp-1023 {
    padding: 69px 0 0;
  }

  @include vp-767 {
    padding: 74px 0 0;
  }

  .faq__title-serif {
    margin-top: -14px;

    @include vp-1079 {
      margin-top: -3px;
    }

    @include vp-767 {
      margin-top: -1px;
    }
  }
}
