.language-select {

  &__list {
    list-style: none;
    margin: 0;
    padding: 0 0 0 23px;
  }

  &__item {

    @include vp-767 {
      cursor: pointer;
      margin: 0 0 20px 0;
      color: $color-default-white;
      background-color: transparent;
      font-size: 16px;
      line-height: 21px;
      font-family: $ff-ibm-plex-sans;
      font-weight: 500;
      transition: color $trans-default;

      @media (hover: hover),
      screen and (min-width: 0\0) {

        &:hover {
          color: $color-heliotrope;
        }
      }
    }
  }
}
