.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  background-color: rgba(6, 30, 71, 0.7);
  backdrop-filter: blur(50px);

  transition: opacity $trans-modal, transform $trans-modal,
    visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 60px 40px;

    @include vp-767 {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: pointer;
  }

  &__logo {
    display: none;
    width: 164px;
    height: 28px;
    color: $color-royal-blue;

    @include vp-1023 {
      display: block;
      flex-shrink: 0;
    }
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;

    background-color: $color-default-white;

    @include vp-767 {
      width: 100%;
      padding: 20px;
    }
  }

  &__title {
    margin: 0;
    font-size: 40px;
    line-height: 1.4;
    color: $color-haiti;

    @include vp-1023 {
      font-size: 38px;
    }

    @include vp-767 {
      font-size: 24px;
    }
  }

  &__subtitle {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    @include vp-767 {
      font-size: 18px;
    }
  }

  &__back-link {
    display: inline-flex;
    align-items: center;
    color: $color-heather;
    font-weight: 600;
    transition: opacity $trans-default;

    svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus,
      &:active {
        opacity: 0.7;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 1;

    width: 40px;
    height: 40px;
    padding: 0;
    color: inherit;

    background: $color-transparent;
    border: none;
    cursor: pointer;
    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }
}

.modal--features {
  .modal__wrapper {
    padding: 85px 50px;
    flex-direction: column;

    @include vp-1023 {
      padding: 0;
      height: 100vh;
      overflow: scroll;
      justify-content: flex-start;
    }

    @include vp-767 {
      overflow: hidden;
    }
  }

  .modal__content {
    width: 890px;
    border-radius: 20px;
    padding: 0;

    @include vp-1023 {
      width: 100%;
      border-radius: 0;
    }

    @include vp-767 {
      background-color: #eceff1;
      overflow: scroll;
      padding-bottom: 125px;
    }
  }

  .modal__close-btn {
    display: none;
  }

  @include vp-767 {
    overflow-y: hidden;
  }
}

.modal--start {
  max-width: 100vw;

  .modal__wrapper {
    align-items: flex-start;
    padding: 65px 40px;
  }

  .modal__content {
    padding: 44px 158px 64px;
    width: 742px;
    border-radius: 20px;

    @include vp-1023 {
      padding: 33px 38px;
    }

    @include vp-767 {
      padding: 35px 30px;
    }
  }

  @include vp-1023 {
    background-color: $color-alice-blue;

    .modal-start {
      width: 64%;
      margin: 0 auto;
    }

    .modal__logo {
      margin-bottom: 30px;
    }

    .modal__wrapper {
      padding: 10px;
      max-height: 100vh;
    }

    .modal__content {
      display: flex;
      flex-direction: column;
      width: calc(100vw - 20px);
      height: calc(100vh - 20px);
      overflow: auto;
    }
  }

  @include vp-767 {
    .modal-start {
      width: 100%;
    }
  }
}

.modal--bg-grey {
  background-color: $color-whisper;
}

.modal--sign {
  .modal__wrapper {
    padding: 10px;
  }

  .intro-modal {
    flex: 1 0 0;
    margin-right: 10px;

    @include vp-1023 {
      margin-right: 0;
      flex: none;
      min-height: unset;
    }
  }

  .modal__content {
    display: flex;
    padding: 0;
    width: 100%;
    min-height: calc(100vh - 20px);
    background-color: $color-transparent;

    @include vp-1023 {
      flex-direction: column;
      background-color: $color-default-white;
      border-radius: 20px;
    }
  }

  .modal-sign {
    flex: 1 0 473px;

    @include vp-1279 {
      flex-basis: 278px;
    }
  }
}
