.article {
  padding: 57px 0 100px;

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    margin-top: 0;

    @include vp-767 {
      font-size: 18px;
      margin: 18px 0;
      line-height: 128%;
    }
  }

  dl {
    margin: 0 0 20px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;

    dd,
    dt {
      margin-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .article__lead {
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    margin-top: 0;
    margin-bottom: 40px;

    @include vp-767 {
      font-size: 20px;
      margin-bottom: 28px;
    }
  }

  h1, .h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;

    @include vp-1279 {
      font-size: 44px;
      margin-bottom: 40px;
    }

    @include vp-1023 {
      font-size: 40px;
    }

    @include vp-767 {
      font-size: 24px;
      margin-bottom: 22px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    margin-top: 37px;
    margin-bottom: 29px;

    @include vp-767 {
      font-size: 20px;
      margin: 28px 0;
    }
  }

  @include vp-1023 {
    padding: 40px 0 74px;
  }

  @include vp-767 {
    padding: 28px 0 100px;
  }
}

.article__wrapper {
  max-width: 890px;
  margin: 0 auto;
}

.article__image-wrapper {
  margin-bottom: 24px;
  height: 394px;
  border-radius: 20px;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
  }

  @include vp-1023 {
    margin-bottom: 20px;
  }

  @include vp-767 {
    height: 188px;
    border-radius: 9.52381px;
    margin-bottom: 16px;
  }
}

.article__content-wrapper {
  max-width: 736px;
  margin-right: auto;

  a {
    text-decoration-line: underline;
    color: $color-blue-violet;
    word-wrap: break-word;
    overflow-wrap: break-word;
    transition: outline $trans-600, background-color $trans-600, color  $trans-600, opacity $trans-600;

    @media (hover: hover),
    screen and (min-width: 0\0) {
      &:hover {
        color: $color-medium-purple;
      }
    }
  }

  @include vp-1279 {
    max-width: 775px;
  }

  @include vp-1023 {
    max-width: unset;
  }

  @include vp-767 {
    margin-right: 1px;
  }
}

.article__title-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include vp-1023 {
    position: relative;
  }
}

.article__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  max-width: 70%;
  margin-top: 0;

  @include vp-1023 {
    max-width: unset;
  }
}

.article__sharing-links {
  margin-top: 13px;
  margin-right: 71px;

  @include vp-1279 {
    margin-right: 0;
  }

  @include vp-1023 {
    position: absolute;
    top: -66px;
    right: 0;
    margin-top: 0;
  }

  @include vp-767 {
    top: -72px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.article__info {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: $color-heather;
  margin-bottom: 44px;

  @include vp-1023 {
    margin-bottom: 48px;
  }

  @include vp-767 {
    flex-direction: column;
    margin-bottom: 27px;
  }
}

.article__date {
  font: inherit;
  margin-right: 42px;

  @include vp-767 {
    margin-bottom: 8px;
  }
}

.article__tags {

}

.article__tag {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: $color-heather;
  margin-right: 6px;
  text-transform: uppercase;
  transition: outline $trans-600, background-color $trans-600, color  $trans-600, opacity $trans-600;

  &::before{
    content: "#";
    display: inline-block;
    margin-right: 2px;
  }

  &::after {
    content: "";
    top: 50%;
    left: 50%;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background-color: $color-transparent;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  @media (hover: hover),
  screen and (min-width: 0\0) {
    &:hover {
      color: $color-haiti;
    }
  }

  @include vp-767 {
    font-size: 14px;
  }
}

.article__author-info {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  color: $color-haiti;
  margin-bottom: 42px;

  img {
    background-color: $color-heather2;
    mix-blend-mode: multiply;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    margin-right: 16px;
  }

  @include vp-1023 {
    margin-bottom: 51px;
  }

  @include vp-767 {
    font-size: 16px;
    margin-bottom: 29px;

    img {
      width: 68px;
      height: 68px;
    }
  }
}

.article__author-name {
  font: inherit;
}

.article__author-subtitle {
  font: inherit;
  font-weight: 400;
  opacity: 0.5;

  @include vp-767 {
    font-weight: 600;
  }
}


