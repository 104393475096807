.form-simple {
  display: flex;
  align-items: flex-start;
  min-height: 76px;

  @include vp-767 {
    flex-direction: column;
    align-items: center;
  }

  form {
    display: flex;
    flex: 1;
    align-items: flex-start;

    @include vp-767 {
      display: block;
      width: 100%;
    }
  }

  .custom-input {
    flex: 1;
  }

  .btn--purple,
  &__status {
    margin: 0;
    padding: 17px 0 18px 18px;
    font-weight: 700;
    font-size: 18px;
    min-width: 109px;
    justify-content: flex-end;

    @include vp-767 {
      margin-bottom: 28px;
      padding: 0 20px;
      width: 100%;
      justify-content: center;
    }
  }

  &__status {
    display: none;
    color: $color-silver-tree;
  }

  &__back {
    display: none;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    color: $color-default-white;
    background-color: $color-silver-tree;

    svg {
      width: 12px;
      height: 10px;
    }
  }

  &__link {
    padding: 8px 0;
    color: $color-bismark;
    font-size: 16px;
    transition: color $trans-default;

    @include vp-767 {
      text-align: center;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-heliotrope;
      }
    }
  }

  &__term {
    margin: 0;
    color: $color-bismark;
    line-height: 19px;

    @include vp-767 {
      text-align: center;
    }

    &-link {
      color: $color-royal-blue;
      text-decoration: underline;
      transition: opacity $trans-default;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 0.6;
        }
      }
    }
  }

  .btn--purple {
    margin-top: 2px;
  }

  &.is-success {
    .form-simple__status {
      display: inline-flex;
    }

    .btn--purple {
      display: none;
    }

    .js-input {
      display: none;
    }

    .form-simple__back {
      display: block;
    }
  }
}
