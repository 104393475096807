.avatar-toggle {
  width: 64px;
  height: 64px;

  label {
    padding-left: 0;
  }

  .avatar-toggle__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid $color-default-white;
    overflow: hidden;
    transition: border $trans-default;

    @include vp-1023 {
      width: 60px;
      height: 60px;
    }

    @include vp-767 {
      width: 64px;
      height: 64px;
    }

    img {
      min-width: 64px;
      height: 64px;
      object-fit: cover;

      @include vp-1023 {
        width: 60px;
        height: 60px;
      }
    }
  }

  input:checked + .avatar-toggle__icon {
    border: 2px solid $color-mantis;
  }
}
