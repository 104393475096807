.reviews {
  margin: 10px;

  .container {
    max-width: 100%;
    padding: 0;

    @media (max-width: 1360px) {
      max-width: 1360px;
    }
  }

  @include vp-767 {
    margin: 0 0 0 20px;
  }

  &__slide {
    background-color: $color-solitude;
    border-radius: 20px;
  }

  &__slide-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
  }

  &__deco {
    position: absolute;

    &--1 {
      left: -273px;
      top: -250px;
    }

    &--2 {
      right: 0;
      bottom: 0;
    }

    &--3 {
      color: $color-pale-cornflower-blue;
      top: -90px;
      left: 444px;
    }

    &--4 {
      right: 210px;
      top: 84px;
    }

    @include vp-1279 {
      &--1 {
        left: -385px;
        top: -267px;
      }

      &--2 {
        width: 90%;
        height: 90%;
        right: -219px;
        bottom: -15px;
      }

      &--3 {
        width: 90%;
        height: 90%;
        top: -26px;
        left: -5px;
      }

      &--4 {
        right: 90px;
        top: 50px;
      }
    }

    @include vp-1023 {
      &--1 {
        left: -390px;
        top: -260px;
      }

      &--2 {
        width: 100%;
        height: 100%;
        right: -385px;
        bottom: -80px;
      }

      &--3 {
        width: 105%;
        height: 105%;
        top: -83px;
        left: 88px;
      }

      &--4 {
        right: 93px;
        top: 30px;
      }
    }

    @include vp-767 {
      &--1 {
        width: 315px;
        height: 400px;
        left: -208px;
        top: -176px;
      }

      &--2 {
        width: 452px;
        height: 361px;
        right: -266px;
        bottom: -45px;
      }

      &--3 {
        width: 70%;
        height: auto;
        left: 60px;
        top: 16px;
        color: $color-solitude;
      }

      &--4 {
        width: 57px;
        height: 40px;
        right: 48px;
        top: 30px;
      }
    }
  }

  &__card {
    margin: 0 auto;
    z-index: 2;
    padding: 130px 190px 148px 0;
    min-height: 540px;

    @include vp-1279 {
      padding: 108px 88px 90px 0;
    }

    @include vp-1023 {
      padding: 75px 94px 57px;
      min-height: 428px;
    }

    @include vp-767 {
      padding: 52px 40px 40px 20px;
      min-height: 337px;
    }
  }

  &__stars {
    margin-bottom: 20px;

    @include vp-1279 {
      margin-left: 7px;
    }

    @include vp-1023 {
      margin-left: 5px;
      margin-bottom: 18px;
    }

    @include vp-767 {
      margin-left: 0;
      margin-bottom: 8px;
    }

    svg {
      color: $color-royal-blue;
      margin-right: 1px;

      @include vp-767 {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__text {
    margin-bottom: 30px;
    width: 700px;
    font-size: 32px;
    font-weight: 500;
    line-height: 130%;
    color: $color-haiti;

    .font-serif {
      margin-right: 10px;

      @include vp-767 {
        margin-right: 4px;
      }
    }

    @include vp-1279 {
      margin-bottom: 109px;
    }

    @include vp-1023 {
      width: 100%;
      margin-bottom: 65px;
    }

    @include vp-767 {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 116px;
    }
  }

  &__author {
    display: flex;
    align-items: center;
  }

  &__img {
    margin-right: 13px;

    img {
      border-radius: 10px;
    }
  }

  &__author-name {
    font-style: normal;
    font-size: 20px;
    font-weight: 500;

    @include vp-767 {
      font-size: 16px;
    }
  }

  .swiper-button-disabled svg {
    color: $color-heather2;
  }

  &__btn {
    position: absolute;
    top: calc(50% - 17px);
    transform: translateY(-50%);
    z-index: 5;

    @include vp-1279 {
      top: calc(50% - 30px);
    }

    @include vp-767 {
      display: none;
    }

    svg {
      color: $color-royal-blue;
      width: 32px;
      height: 32px;
      transition: color $trans-default;
    }

    &:hover {
      svg {
        color: $color-cornflower-blue;
      }
    }
  }

  &__prev {
    left: 47px;

    @include vp-1279 {
      left: 35px;
    }

    @include vp-1023 {
      left: 15px;
    }
  }

  &__next {
    right: 47px;

    @include vp-1279 {
      right: 35px;
    }

    @include vp-1023 {
      right: 15px;
    }
  }
}
