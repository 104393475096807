.sign-up {
  margin: 0 0 0 29px;

  @include vp-1199 {
    margin: 0 0 0 15px;
  }

  @include vp-1079 {
    margin: 0 0 0 17px;
  }

  @include vp-1023 {
    margin: 0;
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button {
    display: flex;

    span {
      font-size: 18px;
      line-height: 21px;

      @include vp-1079 {
        font-size: 16px;
        line-height: 19px;
      }
    }

    span:first-child {
      font-weight: 700;
      font-family: $ff-ibm-plex-sans;
    }

    span:last-child {
      margin: 0 0 0 4px;
    }
  }
}

.sign-up__button.btn--bg {
  padding: 16px 21px;
  width: 169px;
  border-radius: 15px;

  @include vp-1079 {
    width: 155px;
    border-radius: 10px;
  }

  @include vp-1023 {
    padding: 14px 20px 15px;
    border-radius: 10px;
    width: 88%;
    display: flex;
    justify-content: center;
  }
}
