.reasons {

}

.reasons__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 116%;
  margin: 0 0 50px;

  b {
    font-weight: 700;
  }

  @include vp-1279 {
    font-size: 44px;
    margin: 0 0 55px;
  }

  @include vp-1023 {
    font-size: 40px;
    margin: 0 0 49px;
  }

  @include vp-767 {
    font-size: 24px;
    margin: 0 0 40px;
  }
}

.reasons__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -40px;
}

.reasons__item {
  width: calc((100% - 40px)/2);
  color: $color-heliotrope;
  background: $color-default-white;
  border-radius: 20px;
  padding: 40px;
  margin-top: 40px;
  flex-shrink: 0;
  min-height: 400px;

  &:nth-child(2) {
    color: $color-mantis;
  }
  &:nth-child(3) {
    color: $color-royal-blue;
  }

  svg {
    margin-bottom: 9px;
  }

  @include vp-1279 {
    padding: 32px;
    min-height: 342px;
    border-radius: 15px;

    svg {
      width: 52px;
      height: 52px;
      margin-bottom: 15px;
    }
  }

  @include vp-1023 {
    width: 100%;
    min-height: 270px;

    svg {
      margin-bottom: 15px;
    }
  }

  @include vp-767 {
    padding: 32px 19px 32px 32px;
    min-height: 396px;
  }
}

.reasons__item-title {
  color: inherit;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  margin: 0 0 12px;

  @include vp-1279 {
    font-size: 20px;
    margin: 0 0 18px;
  }

  @include vp-1023 {
    margin: 0 0 11px;
  }
}

.reasons__item-text {
  color: $color-bismark;
  opacity: 0.7;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  margin: 0;

  @include vp-1279 {
    font-size: 16px;
  }

  @include vp-767 {
    line-height: 137%;
  }
}
