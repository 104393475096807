.intro {
  position: relative;
  margin-right: 10px;
  margin-top: -109px;
  margin-left: 10px;
  margin-bottom: 180px;
  background-color: $color-royal-blue;
  border-radius: 20px;

  &--bg-blue {
    background-color: $color-pale-cornflower-blue;
  }

  &--bg-green {
    background-color: $color-tea-green;
  }

  &--bg-lilac {
    background-color: $color-mauve;
  }

  .intro__img-wrapper {
    position: absolute;
  }

  &__wrapper {
    position: relative;
    padding-top: 217px;
    padding-bottom: 130px;

    @include vp-1279 {
      padding-top: 176px;
      padding-bottom: 180px;
    }

    @include vp-1023 {
      padding-top: 130px;
      padding-bottom: 400px;
    }

    @include vp-767 {
      padding-top: 142px;
      padding-bottom: 278px;
    }
  }

  &__img {
    position: relative;
  }

  &__content {
    margin-bottom: 57px;
    max-width: 52%;
    color: $color-default-white;
  }

  &__title {
    color: inherit;
    font-size: 48px;
    line-height: 120%;
    margin: 0;

    .font-serif {
      font-weight: 600;
    }

    @include vp-1279 {
      font-size: 44px;
    }
  }

  &__subtitle {
    @include vp-1023 {
      text-align: center;
    }

    @include vp-767 {
      font-size: 18px;
    }
  }

  &__text {
    color: inherit;
    margin: 11px 10px 0 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    opacity: 0.5;

    @include vp-1279 {
      font-size: 18px;
      margin: 14px 10px 0 0;
    }
  }

  &__buttons {
    @include vp-1023 {
      text-align: center;
    }

    .btn--border {
      padding: 12px 20px;

      @include vp-1279 {
        padding: 11px 17px;
        font-size: 16px;
      }

      @include vp-767 {
        font-size: 18px;
      }
    }

    .btn--bg {
      margin-right: 16px;
      padding: 15px 20px;

      @include vp-1279 {
        margin-right: 30px;
        font-size: 16px;
        border-radius: 10px;
        padding: 15px 20px 12px;
      }

      @include vp-767 {
        margin-right: 16px;
      }

      @include vp-359 {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.intro--main {
  margin-bottom: 90px;

  @include vp-1279 {
    margin-bottom: 95px;
  }

  @include vp-1023 {
    margin-top: -80px;
  }

  @include vp-767 {
    margin-bottom: 73px;
  }

  .container {
    padding: 0 69px;

    @include vp-1279 {
      padding: 0 58px;
    }

    @include vp-767 {
      padding: 0 10px;
    }
  }

  .intro__content {
    @include vp-1279 {
      max-width: 65%;
      margin-bottom: 50px;
    }

    @include vp-1023 {
      max-width: 100%;
      margin-bottom: 33px;
    }

    @include vp-767 {
      margin-bottom: 25px;
    }
  }

  .intro__img-wrapper {
    left: calc(50% + 57px);
    top: calc(35% - 90px);

    .intro__deco {
      position: absolute;
    }

    .intro__img {
      display: flex;
      opacity: 0;
      width: 578px;
      height: 578px;
      transition: opacity 2s ease-out;

      img {
        clip-path: url(#main1360);
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 502px;
      }

      &.active {
        opacity: 1;

        .intro__animate {
          display: block;

          svg {
            &:first-child {
              animation: stroke-1 3s ease-out;
              animation-fill-mode: forwards;
              animation-delay: 1s;
              stroke-dasharray: 0, 1061;
            }

            &:last-child {
              animation: stroke-2 3s ease-out;
              animation-fill-mode: forwards;
              animation-delay: 0.75s;
              stroke-dasharray: 0, 1211;
            }
          }
        }
      }
    }

    .intro__animate {
      display: none;
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      svg {
        fill: none;
      }

      &--1 {
        top: -53px;
        left: 45%;
      }

      &--2 {
        top: 74px;
        left: 43%;
      }

      &--3 {
        top: 5px;
        left: 44%;
      }

      &--4 {
        top: 12px;
        left: 45%;
      }

      &--5 {
        top: 16px;
        left: 38%;
      }
    }

    .intro__image-container {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include vp-1279 {
      left: calc(50% - 20px);
      top: calc(45% - 20px);

      .intro__deco {
        top: -13px;
        left: 2px;

        svg {
          width: 218px;
          height: 347px;
        }
      }

      .intro__animate {
        &--1 {
          top: -53px;
          left: 45%;
        }

        &--2 {
          top: 4px;
          left: 47%;
        }

        &--3 {
          top: -50px;
          left: 56%;
        }

        &--4 {
          top: -25px;
          left: 55%;
        }

        &--5 {
          top: -56px;
          left: 44%;
        }

        svg {
          width: 476px;
        }
      }

      .intro__img {
        width: 388px;
        height: 433px;

        img {
          clip-path: url(#main1024);
          object-position: center;
        }
      }
    }

    @include vp-1023 {
      width: 100%;
      left: 0;
      right: unset;
      top: calc(45% + 53px);

      .intro__image-container {
        left: 55%;
        transform: translateX(-50%);
      }

      .intro__animate {
        &--1 {
          top: -87px;
          left: 41%;
        }

        &--2 {
          top: -21px;
          left: 44%;
        }

        &--3 {
          top: -64px;
          left: 52%;
        }

        &--4 {
          top: -64px;
          left: 48%;
        }

        &--5 {
          top: -78px;
          left: 42%;
        }

        svg {
          width: 436px;
        }
      }

      .intro__deco {
        top: -23px;
        left: -5px;

        svg {
          width: 205px;
          height: 347px;
        }
      }

      .intro__img {
        width: 413px;
        height: 526px;

        img {
          width: 388px;
          height: 395px;
          clip-path: url(#main768);
        }
      }
    }

    @include vp-767 {
      top: calc(45% + 69px);
      left: 0;
      right: unset;
      width: 100%;

      .intro__image-container {
        left: calc(50% + 24px);
        transform: translateX(-50%);
      }

      .intro__img {
        width: 300px;
        height: 285px;

        img {
          width: 257px;
          height: 286px;
          clip-path: url(#main360);
        }
      }

      .intro__deco {
        left: 42%;
        top: -9px;
        transform: translateX(-92%);

        svg {
          width: 144px;
          height: 229px;
        }
      }

      .intro__animate {
        &--1 {
          top: -27px;
          left: 43%;
        }

        &--2 {
          top: 0;
          left: 42%;
        }

        &--3 {
          top: -20px;
          left: 47%;
        }

        &--4 {
          top: 2px;
          left: 45%;
        }

        &--5 {
          top: -19px;
          left: 38%;
        }

        svg {
          width: 330px;
          height: 300px;
        }
      }
    }

    @include vp-359 {
      .intro__image-container {
        top: 50px;
        left: calc(50% + 14px);
      }

      .intro__img {
        width: 270px;
        height: 300px;
      }

      .intro__animate {
        left: calc(50% - 20px);
        top: 0;

        &--2 {
          top: 0;
          left: 44%;
        }

        &--3 {
          top: 11px;
        }

        &--4 {
          left: calc(54% - 19px);
          top: 9px;
        }

        svg {
          width: 300px;
          height: 270px;
        }
      }
    }
  }

  .intro__title {
    .font-serif {
      display: block;
    }

    @include vp-1023 {
      text-align: center;
      font-size: 40px;
    }

    @include vp-767 {
      font-size: 24px;
    }
  }
}

.intro--about {
  min-height: 436px;
  overflow: hidden;
  margin-top: -111px;
  margin-bottom: 120px;
  background-color: $color-cornflower-blue;

  .font-serif {
    display: inline;
  }

  @include vp-1279 {
    min-height: 462px;
    margin-bottom: 98px;
  }

  @include vp-1023 {
    margin-top: -80px;
    min-height: 458px;
    margin-bottom: 70px;
  }

  @include vp-767 {
    min-height: 550px;
    border-radius: 16px;
    margin-bottom: 45px;
    background-size: 110%;
  }

  .intro__deco {
    position: absolute;
    top: calc(50% - 53px);
    left: calc(50% + 10px);
    color: $color-tea-green;

    @include vp-1279 {
      top: calc(50% - 66px);
      left: calc(50% + 51px);
    }

    @include vp-1023 {
      top: calc(50% - 103px);
      left: calc(50% + 28px);
    }

    @include vp-767 {
      top: calc(50% - 8px);
      left: calc(50% - 11px);
      width: 355px;
      height: 355px;
    }
  }

  .intro__wrapper {
    position: static;
    max-width: 42%;
    padding-top: 180px;
    padding-bottom: 40px;
    margin: 0 -10px;

    @include vp-1279 {
      max-width: 52%;
      padding-top: 169px;
      margin: 0 -10px;
    }

    @include vp-1023 {
      padding-top: 126px;
      max-width: 60%;
    }

    @include vp-767 {
      padding-top: 313px;
      margin: 0 10px;
      max-width: unset;
    }
  }

  .intro__img-wrapper {
    top: -6px;
    left: 46%;
    right: unset;

    .intro__img {
      clip-path: url(#about1360);
    }

    img {
      height: auto;
    }

    @media (max-width: 1360px) {
      top: 0;
    }

    @include vp-1279 {
      left: 49%;

      .intro__img {
        clip-path: url(#about1024);
      }

      img {
        max-width: unset;
      }
    }

    @include vp-1023 {
      left: 55%;

      .intro__img {
        width: 523px;
        height: 330px;
        clip-path: url(#about768);
      }

      img {
        max-width: 100%;
      }
    }

    @include vp-767 {
      left: calc(100% - 246px);

      .intro__img {
        width: 380px;
        height: 289px;
        clip-path: url(#about360);
        transform: translateZ(0);
      }

      img {
        max-width: unset;
        width: auto;
        height: 100%;
      }
    }
  }

  .intro__content {
    width: 90%;
    margin-bottom: 40px;
    max-width: 750px;
  }

  .intro__title {

    @include vp-1023 {
      line-height: 120%;
    }

    @include vp-767 {
      font-size: 24px;
    }
  }

  .intro__text {
    @include vp-1023 {
      font-size: 18px;
      line-height: 140%;
      margin: 23px 10px 0 0;
    }

    @include vp-767 {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      margin: 12px 10px 0 0;
    }
  }
}

.intro--price {
  margin-top: -109px;
  margin-bottom: 26px;

  @include vp-1079 {
    margin-bottom: 63px;
  }

  @include vp-1279 {
    margin-bottom: 95px;
  }

  @include vp-1023 {
    margin-top: -74px;
    margin-bottom: 40px;
  }

  @include vp-767 {
    margin-bottom: 46px;
    margin-top: -79px;
  }

  .container {
    padding: 0 70px;

    @include vp-767 {
      padding: 0 30px;
    }
  }

  .intro__wrapper {
    padding: 190px 0 127px;

    @include vp-1079 {
      padding: 190px 0 130px;
    }

    @include vp-1023 {
      padding: 163px 0 141px;
    }

    @include vp-767 {
      padding: 127px 0 93px;
    }
  }

  .intro__content {
    margin: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .intro__title {
    font-family: $ff-ibm-plex-sans;
    font-weight: 600;
    display: block;
    margin: 0 0 16px 0;
    text-align: center;
    transform: translate(-7px, -2px);

    @include vp-1023 {
      font-size: 40px;
      line-height: 48px;
    }

    @include vp-767 {
      font-size: 24px;
      line-height: 29px;
      transform: translate(0, 0);
    }

    span {
      @include vp-767 {
        display: block;
      }
    }
  }

  .intro__text {
    margin: 0;
    padding: 0;
    font-family: $ff-ibm-plex-sans;
    font-weight: 600;
    max-width: 571px;
    text-align: center;

    @include vp-1079 {
      font-size: 20px;
      line-height: 26px;
    }

    @include vp-767 {
      font-size: 16px;
      line-height: 21px;
    }

    span {
      display: block;
    }
  }
}

.intro--features {
  min-height: 586px;

  @include vp-1023 {
    margin-top: -80px;
  }

  @include vp-767 {
    .container {
      padding: 0 30px;
    }
  }

  button {
    display: none;

    @include vp-1023 {
      display: block;
      padding: 14px 18px;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
    }

    @include vp-767 {
      max-width: 153px;
    }
  }

  .font-serif {
    display: inline;
  }

  .intro__wrapper {
    padding-top: 180px;
    padding-bottom: 70px;
    margin: 0 -10px;

    @include vp-1279 {
      padding-top: 149px;
      padding-bottom: 97px;
    }

    @include vp-1023 {
      padding-top: 130px;
      padding-bottom: 70px;
    }

    @include vp-767 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      padding-top: 104px;
      padding-bottom: 18px;
    }
  }

  .intro__img-wrapper {
    @include vp-767 {
      position: static;
      order: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 18px;
    }
  }

  .intro__content {
    margin-bottom: 75px;
    max-width: 50%;

    @include vp-1279 {
      max-width: unset;
      margin-bottom: 20px;
    }
  }

  .intro__title {
    color: $color-haiti;

    @include vp-1023 {
      font-size: 40px;
      line-height: 120%;
    }

    @include vp-767 {
      font-size: 24px;
      margin-bottom: 14px;
    }
  }

  .intro__text {
    color: $color-bismark;

    @include vp-1279 {
      max-width: 47%;
      line-height: 138%;
      margin-top: 12px;
    }

    @include vp-1023 {
      max-width: 55%;
      font-size: 16px;
      line-height: 130%;
      margin-bottom: 28px;
    }

    @include vp-767 {
      margin: 0;
      max-width: unset;
      font-size: 14px;
    }
  }

  .intro__features-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    max-width: 50%;
    margin: -36px 0 0;
    padding: 0;
    flex: 0 0 calc(100% / 2);
    justify-content: space-between;

    @include vp-1279 {
      margin: -30px 0 0;
    }

    @include vp-1023 {
      margin: 10px 0 0;
      max-width: 30%;
    }

    @include vp-767 {
      max-width: unset;
      margin: 17px 0 27px;
      justify-content: flex-start;
    }
  }

  .intro__features {
    display: flex;
    width: calc((100% - 20px) / 2);
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: $color-haiti;
    margin-top: 36px;
    padding-left: 10px;

    &:before {
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.5' cy='12.5' r='12.5' fill='%232F77EE'/%3E%3Cpath d='M7.57568 11.7428L12.1211 17.0459L18.9393 7.57617' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 8px;
    }

    @include vp-1279 {
      width: 100%;
      padding-left: 0;
      margin-top: 28px;
    }

    @include vp-1023 {
      font-size: 16px;
      margin-top: 24px;

      &:before {
        align-self: flex-start;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10.5' r='10' fill='%232F77EE'/%3E%3Cpath d='M6.06061 9.89388L9.69697 14.1363L15.1515 6.56055' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }

    @include vp-767 {
      width: calc(100% / 2);
      font-size: 12px;
      margin-top: 12px;
      padding-right: 25px;

      &::before {
        width: 13px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='6.00223' cy='6' rx='6' ry='6' fill='%232F77EE'/%3E%3Cpath d='M3.63989 5.63477L5.82171 8.18022L9.09444 3.63477' stroke='white' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .intro__img-wrapper {
    left: calc(50% + 108px);
    top: calc(50% - 165px);

    @include vp-1279 {
      left: calc(52% + 15px);
      top: calc(38% - 10px);

      img {
        width: 346px;
      }
    }

    @include vp-1023 {
      left: calc(42% + 15px);
      top: calc(46% - 10px);

      img {
        width: 320px;
      }
    }

    @include vp-767 {
      order: 1;
      left: 50%;
      top: unset;
      position: relative;
      transform: translateX(-50%);
      padding: 0;

      .intro__img {
        margin: 0 auto;
      }

      img {
        width: 225px;
      }
    }
  }

  .intro__animate {
    position: absolute;
    left: -88px;
    top: 20px;

    svg {
      fill: none;
    }

    @include vp-1279 {
      left: -79px;
      top: -28px;

      svg {
        width: 488px;
      }
    }

    @include vp-1023 {
      left: -89px;
      top: -33px;

      svg {
        width: 484px;
      }
    }

    @include vp-767 {
      left: calc(50% - 162px);
      top: -86px;

      svg {
        width: 324px;
      }
    }

    @include vp-359 {
      left: calc(50% - 141px);
      top: -101px;

      svg {
        width: 280px;
      }
    }
  }
}

.intro--features-site {
  .intro__text {
    color: $color-haiti;
  }

  .intro__img-wrapper {
    left: calc(50% + 151px);
    top: calc(50% - 149px);

    img {
      clip-path: url(#features-site1360);
      object-fit: cover;
      object-position: center;
      height: auto;
      width: 427px;
    }

    @include vp-1279 {
      left: calc(52% + 15px);
      top: calc(33% - 10px);
      img {
        width: 427px;
        max-width: unset;
      }
    }

    @include vp-1023 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 363px;
        clip-path: url(#features-site1024);
      }
    }

    @include vp-767 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 297px;
        clip-path: url(#features-site768);
      }
    }

    @include vp-359 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 230px;
        clip-path: url(#features-site360);
      }
    }
  }

  .intro__animate {
    left: -79px;
    top: -53px;

    @include vp-1279 {
      left: -79px;
      top: -53px;
    }

    @include vp-1023 {
      left: -17px;
      top: -99px;

      svg {
        width: 396px;
      }
    }

    @include vp-767 {
      left: -19px;
      top: -18px;

      svg {
        height: 300px;
      }
    }

    @include vp-359 {
      left: -37px;
      top: -45px;
    }
  }

  .intro__content {
    margin-bottom: 43px;
    max-width: 52%;
  }
}

.intro--features-tracking {
  .intro__text {
    color: $color-haiti;
  }

  .intro__img-wrapper {
    left: calc(50% + 110px);
    top: calc(50% - 140px);

    img {
      clip-path: url(#features-tracking1360);
      object-fit: cover;
      object-position: center;
      height: auto;
      width: 427px;
    }

    @include vp-1279 {
      left: calc(52% + 15px);
      top: calc(33% - 10px);
      img {
        width: 427px;
        max-width: unset;
      }
    }

    @include vp-1023 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 278px;
        clip-path: url(#features-tracking768);
      }
    }

    @include vp-767 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 297px;
        clip-path: url(#features-tracking768);
      }
    }

    @include vp-359 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 230px;
        clip-path: url(#features-tracking360);
      }
    }
  }

  .intro__animate {
    left: -147px;
    top: -93px;

    @include vp-1279 {
      left: -76px;
      top: -123px;
    }

    @include vp-1023 {
      left: -71px;
      top: -41px;

      svg {
        width: 396px;
        height: 351px;
      }
    }

    @include vp-767 {
      left: -19px;
      top: -18px;

      svg {
        height: 300px;
      }
    }

    @include vp-359 {
      left: -81px;
      top: -37px;

      svg {
        height: 300px;
        width: unset;
      }
    }
  }

  .intro__content {
    margin-bottom: 43px;
    max-width: 52%;
  }
}

.intro--features-app {
  .intro__text {
    color: $color-haiti;
  }

  .intro__img-wrapper {
    left: calc(50% + 151px);
    top: calc(50% - 149px);

    img {
      clip-path: url(#features-app1360);
      object-fit: cover;
      object-position: center;
      height: auto;
      width: 427px;
    }

    @include vp-1279 {
      left: calc(55% + 15px);
      top: calc(36% - 10px);
      img {
        clip-path: url(#features-app1024);
        width: 365px;
        max-width: unset;
      }
    }

    @include vp-1023 {
      left: calc(41% + 15px);
      top: calc(43% - 10px);

      img {
        width: 363px;
      }
    }

    @include vp-767 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 268px;
        clip-path: url(#features-app360);
      }
    }

    @include vp-359 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 230px;
        clip-path: url(#features-app320);
      }
    }
  }

  .intro__animate {
    left: -31px;
    top: 21px;

    @include vp-1279 {
      left: -22px;
      top: -13px;

      svg {
        width: 396px;
      }
    }

    @include vp-1023 {
      left: -62px;
      top: 33px;

      svg {
        height: 300px;
      }
    }

    @include vp-767 {
      left: -9px;
      top: -14px;

      svg {
        width: 277px;
      }
    }

    @include vp-359 {
      left: -31px;
      top: -49px;
    }
  }

  .intro__content {
    margin-bottom: 43px;
    max-width: 52%;
  }
}

.intro--features-time {


  .intro__img-wrapper {
    left: calc(56% + 57px);
    top: calc(38% - 90px);

    .intro__animate {
      top: 1px;
      left: -24%;
    }

    .intro__img {
      img {
        clip-path: url(#features-time1360);
        object-fit: cover;
        object-position: center;
      }
    }

    @include vp-1279 {
      left: calc(57% - 20px);
      top: calc(37% - 20px);

      .intro__animate {
        top: -53px;
        left: -10%;

        svg {
          width: 376px;
        }
      }

      .intro__img {
        img {
          width: 326px;
          clip-path: url(#features-time1024);
          object-position: center;
        }
      }
    }

    @include vp-1023 {
      left: calc(59% - 20px);
      top: calc(42% - 20px);

      .intro__animate {
        top: -76px;
        left: -12%;

        svg {
          width: 336px;
        }
      }

      .intro__img {
        img {
          width: 279px;
          clip-path: url(#features-time768);
        }
      }
    }

    @include vp-767 {
      .intro__img {
        img {
          width: 257px;
          clip-path: url(#features-time360);
        }
      }

      .intro__animate {
        top: -3px;
        left: -23px;
        svg {
          width: 300px;
          height: 270px;
        }
      }
    }

    @include vp-359 {
      .intro__img {
        width: 270px;
        height: 300px;
      }

      .intro__animate {
        top: -3px;
        left: -1px;

        svg {
          width: 270px;
          height: 250px;
        }
      }
    }
  }

  .intro__content {
    margin-bottom: 43px;
    max-width: 52%;
    .intro__text {
      color: $color-haiti;
    }
  }
}

.intro--features-messenger {
  .intro__text {
    color: $color-haiti;
  }

  .intro__img-wrapper {
    left: calc(46% + 143px);
    top: calc(50% - 149px);

    img {
      clip-path: url(#features1360);
      object-fit: cover;
      object-position: center;
      height: auto;
      width: 463px;
    }

    @include vp-1279 {
      left: calc(56% + 15px);
      top: calc(35% - 10px);
      img {
        width: 387px;
        max-width: unset;
        clip-path: url(#features1024);
      }
    }

    @include vp-1023 {
      left: calc(46% + 15px);
      top: calc(37% - 10px);

      img {
        width: 363px;
        clip-path: url(#features768);
      }
    }

    @include vp-767 {
      img {
        width: 252px;
        clip-path: url(#features360);
      }
    }

    @include vp-359 {
      left: 55%;
      img {
        width: 252px;
      }
    }
  }

  .intro__animate {
    left: -83px;
    top: 3px;

    @include vp-1279 {
      left: -79px;
      top: -53px;
    }

    @include vp-1023 {
      left: -40px;
      top: -55px;

      svg {
        width: 396px;
      }
    }

    @include vp-767 {
      left: -46px;
      top: -12px;

      svg {
        height: 239px;
      }
    }

    @include vp-359 {
      left: -37px;
      top: -5px;
    }
  }

  .intro__content {
    margin-bottom: 43px;
    max-width: 52%;
  }
}

.intro--features-history {
  .intro__text {
    color: $color-haiti;
  }

  .intro__img-wrapper {
    left: calc(50% + 151px);
    top: calc(50% - 149px);

    img {
      clip-path: url(#features-history1360);
      object-fit: cover;
      object-position: center;
      height: auto;
      width: 463px;
    }

    @include vp-1279 {
      left: calc(50% + 15px);
      top: calc(29% - 10px);
      img {
        width: 434px;
        max-width: unset;
        clip-path: url(#features-history1024);
      }
    }

    @include vp-1023 {
      left: calc(46% + 15px);
      top: calc(38% - 10px);

      img {
        width: 363px;
      }
    }

    @include vp-767 {
      left: calc(46% + 15px);
      top: calc(49% - 10px);

      img {
        width: 297px;
        clip-path: url(#features-history768);
      }
    }

    @include vp-359 {
      left: 47%;
      top: unset;
      img {
        width: 247px;
        clip-path: url(#features-history360);
      }
    }
  }

  .intro__animate {
    left: -47px;
    top: -13px;

    @include vp-1279 {
      left: -21px;
      top: -17px;
    }

    @include vp-1023 {
      left: -17px;
      top: -37px;

      svg {
        width: 396px;
      }
    }

    @include vp-767 {
      left: -13px;
      top: -4px;

      svg {
        height: 300px;
      }
    }

    @include vp-359 {
      left: -17px;
      top: -23px;
    }
  }

  .intro__content {
    margin-bottom: 43px;
    max-width: 52%;
  }
}

.intro--download {
  margin-top: -109px;
  margin-bottom: 71px;
  overflow: hidden;

  .container {
    padding: 0 70px;

    @include vp-767 {
      padding: 0 30px;
    }
  }

  .intro__wrapper {
    padding: 190px 0 103px;

    @include vp-1079 {
      padding: 173px 0 133px;
    }

    @include vp-1023 {
      padding: 184px 0 168px;
    }

    @include vp-767 {
      padding: 225px 0 180px;
    }
  }

  .intro__content {
    margin: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .intro__title {
    font-family: $ff-ibm-plex-sans;
    font-weight: 600;
    display: block;
    margin: 0 0 16px 0;
    text-align: center;
    transform: translate(-7px, -2px);
    z-index: 2;

    @include vp-1023 {
      font-size: 40px;
      line-height: 48px;
    }

    @include vp-767 {
      font-size: 24px;
      line-height: 29px;
      transform: translate(0, 0);
    }

    span {
      @include vp-767 {
        display: block;
      }
    }
  }

  .intro__text {
    margin: 0;
    padding: 0;
    font-family: $ff-ibm-plex-sans;
    font-weight: 600;
    max-width: 571px;
    text-align: center;
    z-index: 2;

    @include vp-1279 {
      font-size: 18px;
      line-height: 130%;
      max-width: 510px;
    }

    @include vp-1023 {
      font-size: 16px;
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 18px;
    }

    span {
      display: block;
    }
  }

  .intro__deco {
    position: absolute;

    &--1 {
      top: calc(-22% - 57px);
      left: calc(-20% - 19px);
    }

    &--2 {
      top: calc(72% - 53px);
      left: calc(-21% - 14px);
    }

    &--3 {
      top: calc(44% - 53px);
      left: calc(80% - 25px);
    }

    @include vp-1279 {

      &--1 {
        top: calc(-22% - 57px);
        left: calc(-32% - 21px);
      }

      &--2 {
        top: calc(73% - 54px);
        left: calc(-34% - 11px);
      }

      &--3 {
        top: calc(36% - 53px);
        left: calc(85% - 25px);
      }

    }

    @include vp-1023 {

      &--1 {
        top: calc(-11% - 57px);
        left: calc(-52% - 19px);
      }

      &--2 {
        top: calc(72% - 53px);
        left: calc(-54% - 14px);
      }

      &--3 {
        top: calc(58% - 53px);
        left: calc(78% - 25px);
      }
    }

    @include vp-767 {

      &--1 {
        top: calc(-49% - 57px);
        left: calc(-101% - 19px);
      }

      &--2 {
        top: calc(29% - 53px);
        left: calc(-130% - 14px);
      }

      &--3 {
        top: calc(63% - 53px);
        left: calc(44% - 25px);
      }
    }
  }
}

.intro--faq {
  margin-top: -109px;
  margin-bottom: 71px;
  overflow: hidden;

  @include vp-1023 {
    margin-top: -76px;
  }

  .container {
    padding: 0 70px;

    @include vp-767 {
      padding: 0 30px;
    }
  }

  .intro__wrapper {
    padding: 190px 0 124px;

    @include vp-1279 {
      padding: 181px 0 143px;
    }

    @include vp-1023 {
      padding: 151px 0 182px;
    }

    @include vp-767 {
      padding: 222px 0 193px;
    }
  }

  .intro__content {
    margin: 0;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .intro__title {
    font-family: $ff-ibm-plex-sans;
    font-weight: 600;
    display: block;
    margin: 0 0 16px 0;
    text-align: center;
    transform: translate(-7px, -2px);
    z-index: 2;

    @include vp-1023 {
      font-size: 40px;
      line-height: 48px;
    }

    @include vp-767 {
      font-size: 24px;
      line-height: 29px;
      transform: translate(0, 0);
    }

    span {
      @include vp-767 {
        display: block;
      }
    }
  }

  .intro__text {
    margin: 0;
    padding: 0;
    font-family: $ff-ibm-plex-sans;
    font-weight: 600;
    max-width: 571px;
    text-align: center;
    z-index: 2;

    @include vp-1279 {
      font-size: 18px;
      line-height: 130%;
      max-width: 510px;
    }

    @include vp-1023 {
      font-size: 16px;
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 21px;
    }

    span {
      display: block;
    }
  }

  .intro__deco {
    position: absolute;

    &--1 {
      top: calc(50% - 50px);
      left: calc(-10% - 19px);
    }

    &--2 {
      top: calc(65% - 53px);
      left: calc(-10% - 14px);
    }

    &--3 {
      top: calc(-47% - 53px);
      left: calc(73% - 25px);
    }

    &--4 {
      top: calc(-48% - 53px);
      left: calc(84% - 25px);
    }

    @include vp-1279 {

      &--1 {
        top: calc(63% - 57px);
        left: calc(-26% - 21px);
      }

      &--2 {
        top: calc(66% - 54px);
        left: calc(-19% - 11px);

        svg {
          width: 424px;
        }
      }

      &--3 {
        top: calc(-53% - 53px);
        left: calc(74% - 25px);

        svg {
          width: 413px;
        }
      }

      &--4 {
        top: calc(-52% - 53px);
        left: calc(87% - 25px);

        svg {
          width: 397px;
        }
      }
    }

    @include vp-1023 {

      &--1 {
        top: calc(63% - 57px);
        left: calc(-26% - 21px);
      }

      &--2 {
        top: calc(66% - 54px);
        left: calc(-19% - 11px);

        svg {
          width: 424px;
        }
      }

      &--3 {
        top: calc(-53% - 53px);
        left: calc(74% - 25px);

        svg {
          width: 413px;
        }
      }

      &--4 {
        top: calc(-52% - 53px);
        left: calc(87% - 25px);

        svg {
          width: 397px;
        }
      }
    }

    @include vp-767 {

      &--1 {
        top: calc(58% - 57px);
        left: calc(-64% - 21px);

        svg {
          width: 300px;
        }
      }

      &--2 {
        top: calc(60% - 57px);
        left: calc(-52% - 21px);

        svg {
          width: 300px;
        }
      }

      &--3 {
        top: calc(-39% - 53px);
        left: calc(70% - 25px);

        svg {
          width: 268px;
        }
      }

      &--4 {
        top: calc(-39% - 53px);
        left: calc(96% - 25px);

        svg {
          width: 268px;
        }
      }
    }
  }
}

@keyframes stroke-1 {
  to {
    stroke-dasharray: 1061, 0;
  }
}

@keyframes stroke-2 {
  to {
    stroke-dasharray: 1211, 0;
  }
}
