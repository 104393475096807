.features-list {

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 calc((100%) / 4);
    justify-content: flex-start;
    margin-top: -40px;
    margin-left: -40px;

    @include vp-1023 {
      margin-top: -50px;
    }

    @include vp-767 {
      flex-direction: column;
      flex: 0 1 100%;
      margin-top: -20px;
      margin-left: -20px;
    }
  }

  svg {
    fill: none;
    margin-bottom: 8px;

    @include vp-1279 {
      flex-shrink: 0;
      margin-right: 20px;
    }

    @include vp-1023 {
      width: 72px;
      height: 72px;
    }

    @include vp-767 {
      margin-right: 18px;
    }
  }

  p {
    margin: 0;
  }

  &__item {
    width: calc((100% - 160px)/4);
    margin-top: 40px;
    margin-left: 40px;

    @include vp-1279 {
      width: calc((100% - 80px)/2);
      display: flex;
      flex-wrap: nowrap;
    }

    @include vp-1023 {
      margin-top: 50px;
    }

    @include vp-767 {
      width: 100%;
      margin-top: 20px;
      margin-left: 20px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: $color-haiti;

    & b {
      font-weight: 600;
    }

    @include vp-1279 {
      padding-right: 50px;
    }

    @include vp-1023 {
      font-weight: 400;
      font-size: 16px;
      line-height: 133%;
      padding-right: 10px;
    }

    @include vp-767 {
      width: 100%;
      line-height: 138%;
      padding-right: 22px;
    }
  }
}
