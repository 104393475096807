.footer {
  margin: 0 10px 10px;

  &--bg {
    background-color: $color-alice-blue;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  padding: 29px 0 40px;
  border-radius: 20px;
  background-color: $color-sapphire;

  @include vp-1279 {
    padding: 36px 0 40px;
  }

  @include vp-1023 {
    padding: 35px 0 73px;
  }

  @include vp-767 {
    padding: 37px 0 30px;
  }
}

.footer__wrapper ul {
  list-style: none;
}
