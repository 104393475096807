.divider {
  position: relative;

  display: flex;
  justify-content: center;
  padding: 19px 0 24px;

  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: $color-heather;

  @include vp-1023 {
    padding: 16px 0 23px;
  }

  @include vp-767 {
    padding: 16px 0;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    content: "";

    height: 1px;
    background-color: $color-pattens-blue;
    transform: translateY(-50%);
  }

  &__text {
    padding: 0 8px;
    background-color: $color-default-white;
    z-index: 1;
  }
}
