.mark {
  font-size: 10px;
  line-height: 13px;
  font-family: $ff-ibm-plex-sans;
  font-weight: 500;
  background-color: $color-royal-blue;
  color: $color-default-white;

  &--delivery-soon {
    padding: 4px 5px;
    border-radius: 23px;
  }

  &--beta {
    border-radius: 50%;
    padding: 4px 7px 1px;
  }

  &--xl {
    font-size: 12px;
    line-height: 16px;

    &.mark--beta {
      padding: 0;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.mark--delivery-soon {
      padding: 3px 8px 5px;
    }
  }

  &--best-value {
    position: absolute;
    top: -17px;
    right: 33px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    font-family: $ff-ibm-plex-sans;
    color: $color-default-white;
    background-color: $color-burnt-sienna;
    border-radius: 12px;
    padding: 8px 12px 10px;

    @include vp-1079 {
      font-size: 16px;
      line-height: 16px;
      top: -15px;
      right: 25px;
    }

    @include vp-1023 {
      border-radius: 10px;
      padding: 8px 10px 10px;
      top: -15px;
      right: 28px;
    }

    @include vp-767 {
      top: -15px;
      right: 20px;
    }
  }
}

.mark--footer {
  margin: 0 0 0 9px;
}
