.password-input {
  position: relative;

  .btn {
    position: absolute;
    top: 0;
    right: 0;

    padding: 17px 24px;
    border-radius: 0;

    @include vp-767 {
      padding: 13px 24px;
    }
  }
}
