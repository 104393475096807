.questions {
  width: 98%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $color-heather;

  @include vp-1279 {
    width: 81%;
  }

  @include vp-1023 {
    width: 100%;
  }

  &__element {
    margin: 0 0 32px;
    padding: 25px 0 0;
    width: 100%;
    border-top: 2px solid $color-heather;

    @include vp-1023 {
      margin: 0 0 28px;
      padding: 21px 0 0;
    }

    @include vp-767 {
      margin: 0 0 14px;
      padding: 16px 0 0;
    }
  }

  &__button {
    cursor: pointer;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: none;

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        .questions__button-text {
          color: $color-blue-violet;
        }

        .questions__button-icon::before,
        .questions__button-icon::after {
          background-color: $color-blue-violet;
          transition: transform $trans-default, background-color $trans-default;
        }
      }

      &:active {
        .questions__button-text {
          color: $purple-heart;
        }

        .questions__button-icon::before,
        .questions__button-icon::after {
          background-color: $purple-heart;
          transition: transform $trans-default, background-color $trans-default;
        }
      }
    }
  }

  &__button-text {
    color: $color-haiti;
    font-family: $ff-ibm-plex-sans;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    max-width: 92%;
    text-align: left;
    transition: color $trans-default;

    @include vp-1023 {
      font-size: 24px;
      line-height: 31px;
    }

    @include vp-767 {
      font-size: 16px;
      line-height: 21px;
      max-width: 86%;
    }
  }

  &__button-icon {
    position: relative;
    transform: translate(-5px, 3px);

    @include vp-1079 {
      transform: translate(8px, 3px);
    }

    @include vp-1023 {
      transform: translate(-5px, 3px);
    }

    @include vp-767 {
      transform: translate(0, -1px);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 3px;
      background-color: $color-heliotrope;
      transition: transform $trans-default, background-color $trans-default;

      @include vp-767 {
        width: 22px;
        height: 2px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 3px;
      background-color: $color-heliotrope;
      transform: rotate(90deg);
      transition: transform $trans-default, background-color $trans-default;

      @include vp-767 {
        width: 22px;
        height: 2px;
      }
    }
  }

  &__wrapper {
    padding: 30px 0 0 0;

    p {
      display: block;
      max-width: 90%;
      margin: 0;
      padding: 0;
      font-family: $ff-ibm-plex-sans;
      font-weight: 500;
      font-size: 18px;
      line-height: 25x;
    }
  }
}

.questions__element.is-active .questions__button-icon::after {
  transform: rotate(45deg);
  transition: transform $trans-default;
}

.questions__element.is-active .questions__button-icon::before {
  transform: rotate(-45deg);
  transition: transform $trans-default;
}
