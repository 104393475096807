.footer-copyright {
  position: relative;
  padding: 55px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @include vp-1023 {
    padding: 42px 0 0 0;
  }

  @include vp-767 {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    transform: translate(-1px, -4px);
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include vp-1279 {
      flex-direction: column;
      align-items: flex-start;
    }

    @include vp-1023 {
      flex-direction: column-reverse;
    }

    @include vp-767 {
      margin: 0 0 17px 0;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    color: $color-default-white;
    font-family: $ff-ibm-plex-sans;
    font-weight: 500;

    @include vp-1279 {
      margin: 0 0 4px 0;
    }

    @include vp-1023 {
      font-weight: 400;
      margin: 0;
    }

    span:not(:first-child) {
      margin: 0 0 0 3px;

      @include vp-767 {
        margin: 0;
        display: block;
      }
    }

    span:first-child {

      @include vp-767 {
        display: none;
      }
    }
  }

  &__links {
    @include vp-1023 {
      margin: 0 0 4px 0;
    }
  }

  &__links-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include vp-767 {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__links-item {
    margin: 0 0 0 40px;

    @include vp-1279 {
      margin: 0 0 0 36px;
    }

    @include vp-1023 {
      margin: 0 0 0 18px;
    }

    @include vp-767 {
      margin: 0 0 8px 0;
    }

    a {
      font-size: 14px;
      line-height: 18px;
      color: $color-default-white;
      font-family: $ff-ibm-plex-sans;
      font-weight: 500;
      text-decoration: underline;
      transition: opacity $trans-default;

      @include vp-1023 {
        font-weight: 400;
      }

      @media (hover: hover),
      screen and (min-width: 0\0) {

        &:hover {
          opacity: 0.3;
        }
      }
    }
  }

  &__links-item:first-child {

    @include vp-1279 {
      margin: 0;
    }

    @include vp-767 {
      margin: 0 0 8px 0;
    }
  }

  &__made-by {

    @include vp-1279 {
      align-self: flex-end;
    }

    @include vp-1023 {
      transform: translate(1px, 2px);
    }

    @include vp-767 {
      align-self: flex-start;
      transform: translate(4px, 3px);
    }

    span {
      font-size: 14px;
      line-height: 18px;
      color: $color-default-white;
      font-family: $ff-ibm-plex-sans;
      font-weight: 400;
    }

    svg {
      margin: 0 0 0 3px;
      color: $color-default-white;

      @include vp-767 {
        margin: 0 0 0 4px;
      }
    }
  }
}

.footer-copyright::before {
  content: "";
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $color-default-white;
  opacity: 0.3;

  @include vp-1279 {
    top: 22px;
  }

  @include vp-767 {
    display: none;
  }
}
